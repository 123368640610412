import '../../Css/CreateAccountPage.css';
import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
// import {Card, Button, Form} from 'react-bootstrap'
import React, {useRef} from 'react';
import BackgroundTop from '../../Svg/BackgroundTopTriangle.svg'
import BackgroundBottom from '../../Svg/BackgroundBottomTriangle.svg'

// import CreateAccount from '../Components/CreateAccount';
// import { AuthProvider } from '../Context/AuthContext';
// import { Container } from 'react-bootstrap';

export default function SonUploaded() {
   

    return (
        <div id= "Song-Uploaded-Page">
            
            <div id= "Direct-Page-Background">
                <div className= "Soundbar-Background-Triangle">
                    <img src={BackgroundTop} className= "Background-Triangle" alt="Background Triangle"></img>
                </div>
                <div className= "Soundbar-Background-Triangle">
                    <img src={BackgroundBottom} className= "Background-Triangle" alt="Background Triangle"></img>
                </div>
                

            </div>
            <header id= "Soundbar-Upload-Box">
                <div id= "useNavigate-Page-Box">
                    <div id= "Sign-Up-Header">
                        <img src={SoundbarLogo} id= "Soundbar-Logo-Title" alt="Logo"></img>

                        <div id= "Sign-Up-Direction">Account Created!</div>
                    </div>

                    <div className= "useNavigate-Page-Text">
                        Your songs will be pre loaded to your account and your 
                        login information will be emailed to you November 19th, a 
                        week before out beta launch
                        On November 19th, a week before out beta launch
                    </div>    

                    <div id= "useNavigate-Button-Stack">
                        <Link to={"/"} style={{ textDecoration: 'none' }} id="Home-useNavigate" className= "SecondaryBackground">

                            Home
                        </Link>

                        <Link to={"/upload"} style={{ textDecoration: 'none' }} id="Home-useNavigate" className= "PrimaryBackground">

                            Upload Another
                        </Link>
                    </div>

                </div>
            </header>
        </div>
    );
}
    