import React, { Component, useState, useEffect, useRef } from "react";
import "../../Css/HomePage.css";
// import MenuIcon from '../../Svg/';
import MenuButton from "../../Svg/newMenuButton.svg";
import {
  BrowserRouter as Router,
  useNavigate,
  Route,
  Link,
} from "react-router-dom";
import MenuDivider from "../../Svg/MenuDivider.svg";
import { AuthProvider, useAuth } from "../Context/AuthContext";
// import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import app from "../../firebase";
import { Firestore } from "@firebase/firestore";
import { motion } from "framer-motion";
import { Image } from "react-bootstrap";
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import AppStoreButton from "../../Svg/appStoreButton.png";
import homeIcon from "../../Svg/homeIcon.svg";
import appIcon from "../../Svg/appIcon.svg";
import helpIcon from "../../Svg/helpIcon.svg";
import searchIcon from "../../Svg/searchIcon.svg";
import profileIcon from "../../Svg/newProfileButton.svg";
import caretDown from "../../Svg/caret-down.svg";
import { Card, Button, Form, Alert } from "react-bootstrap";

import SearchBox from "./SearchBox";

import { SearchProvider, useSearch } from "../Context/AlgoliaContext";

// import { AntDesign } from 'react-web-vector-icons';

export default function MobilMenu() {
  const { searchQuery } = useSearch();
  const { setSearchQuery } = useSearch();
  const { search } = useSearch();

  const [menuState, setMenuState] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  //const [searchQuery, setSearchQuery] = useState("");

  const searchRef = useRef();

  const [profileOpen, setProfileOpened] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [menuHeight, setMenuHeight] = useState("4vw");

  const [showSearchBox, setShowSearchBox] = useState(false);

  // const slideMenu = () => {
  //     // if (currentUser
  //         return menuState ? {} : {right: "-90vw"}
  // }

  useEffect(() => {
    if (!isOpen) {
      const timeout = setTimeout(() => {
        setOpened(false);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      setIsProfileOpen(false);
      setOpened(true);

      const timeout = setTimeout(() => {
        setProfileOpened(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);


  const handleSearchClick = () => {
    searchRef.current.select();
    console.log(searchRef.current.select());
  };

  function updateSearch() {}

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    const updatedSearchQuery = event.target.value;
    search(updatedSearchQuery);
  };

  useEffect(() => {
    if (searchQuery.length > 0) {
      setShowSearchBox(true);
    } else {
      setShowSearchBox(false);
    }
  }, [searchQuery]);

  const menuRender = () => {
    return opened ? {} : { display: "none" };
  };

  const profileRender = () => {
    return profileOpen ? {} : { display: "none" };
  };



  const openMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  //   const openProfile = () => {
  //     setIsProfileOpen((isProfileOpen) => !isProfileOpen);
  //   };

  const profileClick = () => {
    setIsProfileOpen((isProfileOpen) => !isProfileOpen);
  };

  const variants = {
    open: { opacity: 1, x: "0vw" },
    closed: { opacity: 0, x: "37vw" },

    profileOpen: { opacity: 1, x: "0vw" },
    profileClosed: { opacity: 0, x: "37vw" },
  };

  const variantShadow = {
    open: { opacity: 1, display: "block" },
    closed: { opacity: 0, display: "none" },
  };

  // const menuClicked = () => {

  //     setMenuState(!menuState)
  // }

  const { currentUser, logout } = useAuth();
  const { username } = useAuth();
  const { profileImage } = useAuth();
  const history = useNavigate();
  const [error, setError] = useState("");

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <AuthProvider>
        <div id="Menu-Button-Box" height={menuHeight}>
          <motion.nav
            animate={isOpen ? "open" : "closed"}
            variants={variantShadow}
            transition={{ type: "tween", velocity: 1000 }}
            id="menuShadow"
            style={menuRender()}
          />

          <motion.nav
            animate={isProfileOpen ? "open" : "closed"}
            variants={variantShadow}
            transition={{ type: "tween", velocity: 1000 }}
            id="menuShadow"
            style={profileRender()}
          />
          
          <div id="Top-Controls-Box-Mobile">
            <div className="LeftComponentsMobile">
              <Link to={"/"} style={{ textDecoration: "none" }} id="Logo-Box">
                <img src={SoundbarLogo} id="Soundbar-Logo-Mobile" alt="Logo"></img>
              </Link>

              <a
                className="appStoreLink"
                href="https://apps.apple.com/us/app/soundbar-llc/id1556946864"
              >
                <img src={AppStoreButton} id="AppStore-Logo-Mobile" alt="Logo"></img>
              </a>
            </div>

            <div id="menuItems">
              <div class="menu-line"></div>
              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
              >
                {currentUser ? (
                  <Link
                    to={"/profile"}
                    replace
                    style={{ textDecoration: "none" }}
                    className="signText"
                  >
                    <img id="menuImagesMobile" src={profileIcon} />
                  </Link>
                ) : (
                  <Link
                    to={"/login"}
                    replace
                    style={{ textDecoration: "none" }}
                    className="signText"
                  >
                    <img id="menuImagesMobile" src={profileIcon} />
                  </Link>
                )}
              </motion.div>

              <motion.div
                onClick={() => setIsOpen((isOpen) => !isOpen)}
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
              >
                <img
                  src={MenuButton}
                  id="Menu-Button-Mobile"
                  alt="MenuIconButton"
                ></img>
              </motion.div>
            </div>
          </div>

          <div class="yellow-line-mobile"></div>

          <motion.nav
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            transition={{ type: "tween", velocity: 1000 }}
            id="Menu-Box"
            style={menuRender()}
          >
            <div id="Menu-Options">
              {/* <div>
                        <img src={MenuDivider} id= "MenuDivider" alt="Menu Divider"></img>
                    </div> */}

              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                className="MenuButtonFrame"
              >
                {/* <img className="menuIcon" src={appIcon} /> */}

                <Link
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  className="MenuButton"
                >
                  Home
                </Link>
              </motion.div>

              <div className="SpacerDiv"></div>

              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                className="MenuButtonFrame"
              >
                {/* <img className="menuIcon" src={appIcon} /> */}

                <Link
                  to={"/explore"}
                  style={{ textDecoration: "none" }}
                  className="MenuButton"
                >
                  Explore
                </Link>
              </motion.div>

              <div className="SpacerDiv"></div>

              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                className="MenuButtonFrame"
              >
                {/* <img className="menuIcon" src={appIcon} /> */}

                <Link
                  to={"/affiliate"}
                  style={{ textDecoration: "none" }}
                  className="MenuButton"
                >
                  Affiliate
                </Link>
              </motion.div>

              <div className="SpacerDiv"></div>

              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                className="MenuButtonFrame"
              >
                {/* <img className="menuIcon" src={helpIcon} /> */}
                <Link
                  to={"/support"}
                  style={{ textDecoration: "none" }}
                  className="MenuButton"
                >
                  Help
                </Link>
              </motion.div>

              <div className="SpacerDiv"></div>
              {/* 
                    <div>
                        <img src={MenuDivider} id= "MenuDivider" alt="Menu Divider"></img>
                    </div> */}
            </div>
          </motion.nav>
        </div>
      </AuthProvider>
    </>
  );
}
