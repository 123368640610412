import React, { Component, useState, useEffect, useRef } from "react";
import "../../Css/Sidebar.css";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import listensIcon from "../../Svg/listensIcon.svg";

import PlayIconWeb from "../../Svg/PlayIconWeb.png";

import "../../Css/SearchBox.css";
import { SongProvider, useSongControls } from "../Context/SongContext";

import { SearchProvider, useSearch } from "../Context/AlgoliaContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

// import { AntDesign } from 'react-web-vector-icons';

export default function SearchBox() {
  const { searchQuery } = useSearch();
  const { setSearchQuery } = useSearch();
  const { search } = useSearch();
  const { searchData } = useSearch();
  const { suggestedAccounts } = useAuth();
  const { getArtist } = useAuth();
  const history = useNavigate();
  const { showcase } = useAuth();
  const { setSongs } = useSongControls();
  const { setCurrentSong } = useSongControls();
  const { setSongIndex } = useSongControls();

  useEffect(() => {

    
  }, [suggestedAccounts]);

  function intToString(value) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  function Spacer() {
    return <div className="SearchBoxSpacer"></div>;
  }

  function seachItemClicked(item){
    if (item.type == "users"){
      navigateArtistPage(item)

    }else{
      setSong(item)
    }
  }

  function navigateArtistPage(artist) {
    console.log("Item iD:" + artist.id);
    history(`/${artist.id}/`);
  }

  function setSong(clickedSong) {
    console.log("SongLoading");

    setCurrentSong(clickedSong);
    setSongs(clickedSong)
    setSongIndex(0)
  }


  const searchResultsMap = searchData.map((item) => {
    return (
      <>
        <div className="SearchBoxItem" onClick={() => seachItemClicked(item)}>
          <div className="SearchItemLeft">
            <img
              className="SearchImage"
              style={{
                borderRadius: item.type != "users" ? 0 : 300,
              }}
              src={item.image_link}
            ></img>
            <div className="SearchBoxText">
              <p className="SearchTitle">{item.type == "users" ? "@"+`${item.title}` : `${item.song_name}`}</p>
              <p className="SearchSubtitle">{item.artist}</p>
            </div>
          </div>
        </div>
        <Spacer />
      </>
    );
  });

  return (
    <AuthProvider>
      <div className="SearchBox">{searchResultsMap}</div>
    </AuthProvider>
  );
}
