import '../../Css/CreateAccountPage.css';
import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
// import {Card, Button, Form} from 'react-bootstrap'
import React, {useRef} from 'react';
import CreatedBackground from '../../Svg/PageCreatedBackground.svg'
import BackgroundBottom from '../../Svg/BackgroundBottomTriangle.svg'

// import CreateAccount from '../Components/CreateAccount';
// import { AuthProvider } from '../Context/AuthContext';
// import { Container } from 'react-bootstrap';

export default function AccountCreated() {
   
    const styles = {
        container: {
            backgroundImage: `url(${CreatedBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh'
        }
    };
    return (
        <div id= "Create-Account-Page">
            
            <div style= {styles.container} id= "Direct-Page-Background">
                {/* <div className= "Soundbar-Background-Triangle">
                    <img src={CreatedBackground} className= "Page-Created-Background" alt="Background Triangle"></img>
                </div> */}
                
                

            </div>
            <header id= "Soundbar-Sign-Up">
                <div id= "useNavigate-Page-Box">
                    <div id= "Sign-Up-Header">
                        <img src={SoundbarLogo} id= "Soundbar-Logo-Title" alt="Logo"></img>

                        <div id= "Sign-Up-Direction">Account Created!</div>
                    </div>

                    <div className= "useNavigate-Page-Text">
                        Your login information will be emailed to you
                        On November 19th, a week before out beta launch
                    </div>    

                    <div id= "useNavigate-Button-Stack">
                        <Link to={"/"} style={{ textDecoration: 'none' }} id="Home-useNavigate" className= "SecondaryBackground">

                            Home
                        </Link>

                        <Link to={"/upload"} style={{ textDecoration: 'none' }} id="Home-useNavigate" className= "PrimaryBackground">

                            Upload
                        </Link>
                    </div>

                </div>
            </header>
        </div>
    );
}
    