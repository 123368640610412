import "../../Css/CreateAccountPage.css";
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Card, Button, Form, Alert } from "react-bootstrap";
import React, { useRef, useState } from "react";
import CreateAccount from "../Components/CreateAccount";
import { useAuth } from "../Context/AuthContext";

export default function LoginForm() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      await login(emailRef.current.value, passwordRef.current.value)  
      history("/");
    } catch (errorMessage) {
      console.error('An error occurred:', errorMessage);
      setError(errorMessage);
    };

    setLoading(false);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Form id="Text-Boxes" onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Control
                type="email"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={emailRef}
                placeholder="Email"
                required
              />
            </Form.Group>

            <Form.Group id="password">
              <Form.Control
                type="password"
                ref={passwordRef}
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                placeholder="Password"
                required
              />
            </Form.Group>

            {error && (
              <Alert variant="danger" id="Error" className="PrimaryText">
                {error}
              </Alert>
            )}

            <Button
      
              disabled={loading}
              style={{ border: "none" }}
              className="SubmitButtonLogin"
              type="submit"
            >
                <p className="SubmitButtonLoginText">Sign in</p>
                
   
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
