import React from "react"
import { Route, useNavigate, Outlet } from "react-router-dom"
import { useAuth } from "../Context/AuthContext"

const PrivateRoute = () => {
  const { currentUser } = useAuth()

  return currentUser ? <Outlet/> : <useNavigate to="/signup" />
}

export default PrivateRoute