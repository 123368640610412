import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import {
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  where,
  orderBy,
  limit,
  query,
} from "firebase/firestore";
import axios from "axios";

// import "firebase/compat/auth";
import "firebase/storage"; // <----
import { getStorage } from "@firebase/storage";
import "firebase/firestore";

const ShareContext = React.createContext();

export function useShare() {
  return useContext(ShareContext);
}

export function ShareProvider({ children }) {
  const [displaySharePage, setDisplaySharePage] = useState(false);
  const [shareSong, setShareSong] = useState();

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const [loading, setLoading] = useState(true);

  const value = {
    displaySharePage,
    setDisplaySharePage,
    setShareSong,
    shareSong,
  };

  return (
    <ShareContext.Provider value={value}>
      {!loading && children}
    </ShareContext.Provider>
  );
}
