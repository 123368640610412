import React, { useContext, useState, useEffect } from "react";
import algoliasearch from "algoliasearch";
import {
  doc,
  getFirestore,
  getDoc,
} from "@firebase/firestore";

// import "firebase/compat/auth";
import "firebase/storage"; // <----
import "@firebase/firestore";

const SearchContext = React.createContext();

export function useSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const client = algoliasearch(
    "NHFT9V24EF",
    "5b2a007b5ae6603fce30b68c72aac5bc"
  );
  const index = client.initIndex("Explore");

  //   index.setSettings({
  //     searchableAttributes: ["name"],
  //   });

  //
  const [algoliaResults, setAlgoliaResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([
    {
      type: "",
      title: "",
      subTitle: "",
      image: "",
    },
  ]);

  const search = async (searchQuery) => {

    try {
      const results = await Promise.all([
        index.search(searchQuery, { hitsPerPage: 8 }),
      ]);
      const songsResults = results[0].hits;
      //   console.log(songsResults);
      setAlgoliaResults(songsResults);
      //   console.log(algoliaResults);
      const hitsByIndex = {
        Songs: songsResults,
      };
      const indexNames = ["Songs", "Users"];
    } catch (error) {
      console.log(error);
    }

    const newDataArray = [];
    if (algoliaResults && algoliaResults.length) {
      const promises = algoliaResults.map((result) => {
        let type = result.type;
        if (type === "song") {
          type = "songs";
        } else if (type === "artist") {
          type = "users";
        } else if (type === "playlist") {
          type = "playlists";
        } else {
          console.log("ISSUE");
          console.log(type);
        }
        const docRef = doc(getFirestore(), type, result.__id__);
        const docSnapshotPromise = getDoc(docRef);

        return docSnapshotPromise.then((docSnapshot) => {
          if (docSnapshot.exists()) {
            let searchItem;
            if (type === "songs") {
              searchItem = {
                type: type,
                song_name: docSnapshot.get("song_name"),
                artist: docSnapshot.get("artist"),
                image_link: docSnapshot.get("image_link"),
                id: docSnapshot.get("id"),
                full_song_link: docSnapshot.get("full_song_link")
              };
            } else if (type === "users") {
              searchItem = {
                type: type,
                title: docSnapshot.get("username"),
                artist: docSnapshot.get("full_name"),
                image_link: docSnapshot.get("profile_link"),
                id: docSnapshot.get("id")
              };
            }

            return searchItem;
          } else {
            console.log(`Document with UID ${result.__id__} does not exist`);
          }
        });
      });

      Promise.all(promises)
        .then((searchData) => {
          setSearchData(
            searchData.filter((searchItem) => searchItem !== undefined)
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return null;
  };

  useEffect(() => {
    // do nothing
  }, []);

  const value = {
    algoliaResults,
    search,
    searchQuery,
    setSearchQuery,
    searchData,
  };

  return (
    <SearchContext.Provider value={value}>
      {!loading && children}
    </SearchContext.Provider>
  );
}
