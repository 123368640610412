import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getStorage } from '@firebase/storage';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA47uSRz7QPAFRTteTUU1DrMOdhNrpEHdE",
  authDomain: "soundbar-3d263.firebaseapp.com",
  databaseURL: "https://soundbar-3d263.firebaseio.com",
  projectId: "soundbar-3d263",
  storageBucket: "soundbar-3d263.appspot.com",
  messagingSenderId: "1093656573880",
  appId: "1:1093656573880:web:c0a4872cbf8236f5ac814d",
  measurementId: "G-5KLVMB16FQ",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

export default app;


// export const app = initializeApp(firebaseConfig);
// export const functions = getFunctions(app);
// export const storage = getStorage(app);