import React, { useState, useEffect, useRef } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./Js/Pages/HomePage";

import ExplorePage from "./Js/Pages/ExplorePage";

import CreateAccountPage from "./Js/Pages/CreateAccountPage";

import AffiliatePage from "./Js/Pages/AffiliatePage";

import AffiliateSignUpPage from "./Js/Pages/AffiliateSignUpPage";

import UploadPage from "./Js/Pages/UploadPage";

import SongUploaded from "./Js/Pages/SongUploaded";

import SupportPage from "./Js/Pages/SupportPage";

import AffiliateApplied from "./Js/Pages/AffiliateApplied";

import AffiliateApplicationPage from "./Js/Pages/AffiliateApplication";
import AffiliateApplicationCompletePage from "./Js/Pages/AffiliateAppComplete";

import LoginPage from "./Js/Pages/LoginPage";

import AccountCreated from "./Js/Pages/AccountCreated";

import ProfilePage from "./Js/Pages/ProfilePage";

import UserProfilePage from "./Js/Pages/UserProfilePage";

import WebPlayerPage from "./Js/Pages/WebPlayerPage";

import PrivateRoute from "./Js/Components/PrivateRoute";

import { AuthProvider } from "./Js/Context/AuthContext";

import { SearchProvider } from "./Js/Context/AlgoliaContext";

import { PromotionProvider } from "./Js/Context/PromotionContex";

import { SongProvider } from "./Js/Context/SongContext";

import { ShareProvider } from "./Js/Context/ShareContext";

import SongControls from "./Js/Components/SongControls";

import Player from "./Js/Components/Player";

import { EditProfile } from "./Js/Components/EditProfile";

import Menu from "./Js/Components/Menu";
import MobilMenu from "./Js/Components/MobileMenu";

import { BrowserView, MobileView } from "react-device-detect";

function App() {
  return (
    <AuthProvider>
      <ShareProvider>
        <SearchProvider>
          <PromotionProvider>
            <SongProvider>
              <Router>
                <div className="SoundbarWebsite">
                  {/* MENU VIEW ON COMPUTER */}
                  <BrowserView>
                    <Menu />
                  </BrowserView>
                  {/* MENU VIEW ON MOBILE PHONE */}
                  <MobileView>
                    <MobilMenu />
                  </MobileView>

                  <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route
                      exact
                      path="/:username/tracks/:songID"
                      element={<WebPlayerPage />}
                    />
                    <Route
                      exact
                      path="/:username/"
                      element={<UserProfilePage />}
                    />

                    <Route exact path="/editProfile" element={<PrivateRoute />}>
                      <Route
                        exact
                        path="/editProfile"
                        element={<EditProfile />}
                      />
                    </Route>

                    <Route
                      exact
                      path="/upload/redirect"
                      element={<PrivateRoute />}
                    >
                      <Route
                        exact
                        path="/upload/redirect"
                        element={<SongUploaded />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/signup"
                      element={<CreateAccountPage />}
                    />
                    <Route exact path="/login" element={<LoginPage />} />

                    <Route exact path="/explore" element={<ExplorePage />} />
                    <Route
                      exact
                      path="/affiliate"
                      element={<AffiliatePage />}
                    />
                    <Route
                      exact
                      path="/affiliate/apply"
                      element={<AffiliateApplicationPage />}
                    />
                    <Route
                      exact
                      path="/affiliate/apply/applicationsuccess"
                      element={<AffiliateApplicationCompletePage />}
                    />

                    <Route exact path="/account" element={<LoginPage />} />

                    <Route
                      exact
                      path="/signup/redirect"
                      element={<PrivateRoute />}
                    >
                      <Route
                        exact
                        path="/signup/redirect"
                        element={<AccountCreated />}
                      />
                    </Route>

                    <Route
                      exact
                      path="/upload/redirect"
                      element={<PrivateRoute />}
                    >
                      <Route
                        exact
                        path="/upload/redirect"
                        element={<SongUploaded />}
                      />
                    </Route>

                    <Route
                      exact
                      path="/affiliate/redirect"
                      element={<PrivateRoute />}
                    >
                      <Route
                        exact
                        path="/affiliate/redirect"
                        element={<AffiliateApplied />}
                      />
                    </Route>

                    <Route
                      exact
                      path="/affiliate/signup"
                      element={<PrivateRoute />}
                    >
                      <Route
                        exact
                        path="/affiliate/signup"
                        element={<AffiliateSignUpPage />}
                      />
                    </Route>

                    <Route exact path="/upload" element={<PrivateRoute />}>
                      <Route exact path="/upload" element={<UploadPage />} />
                    </Route>

                    <Route exact path="/profile" element={<PrivateRoute />}>
                      <Route exact path="/profile" element={<ProfilePage />} />
                    </Route>

                    <Route exact path="/support" element={<SupportPage />} />
                  </Routes>
                </div>
              </Router>

              <SongControls />
            </SongProvider>
          </PromotionProvider>
        </SearchProvider>
      </ShareProvider>
    </AuthProvider>
  );
}

export default App;
