import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import Underline from '../../Svg/Underline.png';

import '../../Css/UploadPage.css';
// import '../../Css/AffiliateApply.css';

import { BrowserRouter as Router, Switch, Route, Link, useNavigate} from "react-router-dom";
import { React, useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Alert } from "react-bootstrap";
import Select from "react-select";
import HomePlayButton from '../../Svg/HomePlayButton.svg';
import HomePauseButton from '../../Svg/HomePauseButton.svg';
import SocialsLikeButton from '../../Svg/SocialsLikeButton.svg';
import SocialsCommentButton from '../../Svg/SocialsCommentButton.svg';
import SocialsShareButton from '../../Svg/SocialsShareButton.svg';
import tabBar from '../../Svg/tabBar.svg';

import { auth } from '../../firebase';
import { useAuth } from '../Context/AuthContext';
import { doc, setDoc, getFirestore, updateDoc, getDoc, addDoc, collection} from "@firebase/firestore"; 

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "../../firebase"

import { AuthProvider } from '../Context/AuthContext';
import { update } from '@react-spring/core';

const genres = ["Blues","Classic Rock","Country","Dance","Disco","Funk","Grunge",
  "Hip-Hop","Jazz","Metal","New Age","Oldies","Other","Pop","R&B",
  "Rap","Reggae","Rock","Techno","Industrial","Alternative","Ska",
  "Death Metal","Pranks","Soundtrack","Euro-Techno","Ambient",
  "Trip-Hop","Vocal","Jazz+Funk","Fusion","Trance","Classical",
  "Instrumental","Acid","House","Game","Sound Clip","Gospel",
  "Noise","AlternRock","Bass","Soul","Punk","Space","Meditative",
  "Instrumental Pop","Instrumental Rock","Ethnic","Gothic",
  "Darkwave","Techno-Industrial","Electronic","Pop-Folk",
  "Eurodance","Dream","Southern Rock","Comedy","Cult","Gangsta",
  "Top 40","Christian Rap","Pop/Funk","Jungle","Native American",
  "Cabaret","New Wave","Psychadelic","Rave","Showtunes","Trailer",
  "Lo-Fi","Tribal","Acid Punk","Acid Jazz","Polka","Retro",
  "Musical","Rock & Roll","Hard Rock","Folk","Folk-Rock",
  "National Folk","Swing","Fast Fusion","Bebob","Latin","Revival",
  "Celtic","Bluegrass","Avantgarde","Gothic Rock","Progressive Rock",
  "Psychedelic Rock","Symphonic Rock","Slow Rock","Big Band",
  "Chorus","Easy Listening","Acoustic","Humour","Speech","Chanson",
  "Opera","Chamber Music","Sonata","Symphony","Booty Bass","Primus",
  "Porn Groove","Satire","Slow Jam","Club","Tango","Samba",
  "Folklore","Ballad","Power Ballad","Rhythmic Soul","Freestyle",
  "Duet","Punk Rock","Drum Solo","Acapella","Euro-House","Dance Hall","Soundbar"]

const options = genres.map(genre => {
    return { value: genre, label: genre }
})
    
// import { render } from 'react-dom';
// import 'firebase/compat/storage'

export default function UploadPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
     }, []);

    const navigate = useNavigate()
    const [song, setSong] = useState(null)
    const [image, setImage] = useState(null)
    const [hashtags, setHashtags] = useState([]);
    const fullNameRef = useRef("");
    const [songName, setSongName] = useState("");  

    const [progress, setProgress] = useState(0);
    const { currentUser, logout } = useAuth()
    const { username } = useAuth()
    const { profileImage } = useAuth();

    const [addrtype, setAddrtype] = useState([]);

    // const { uploadSong } = useAuth()
    const [errorSong, setErrorSong] = useState(false)
    const [errorImage, setErrorImage] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const Add = addrtype.map((Add) => Add);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);  
    const [playing, setPlaying] = useState(true);
    const songImage = document.getElementById("uploadPlayerImage");
    const imageDisk = document.querySelector('.profile-image');
    const audioStatusIcon = document.getElementById('audioStatusIcon');
    const [rotation, setRotation] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [loopStartTime, setLoopStartTime] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [prevAudioState, setPrevAudioState] = useState('paused');
    
    const audioRef = useRef(null);

    const handleSelectChange = (selectedOptions) => {
        if (selectedOptions.length > 3) {
          selectedOptions = selectedOptions.slice(0, 3);
        }
        setSelectedOption(selectedOptions);
      };
    

    const handleSong = e => {
        if (e.target.files[0]) {
            setSong(e.target.files[0])
            setSelectedAudio(URL.createObjectURL(e.target.files[0]));

        }
    }

    const handleImage = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            setSelectedImage(URL.createObjectURL(e.target.files[0]));

        }
    }
     
    function handleName(evt) {
        console.log("new value", evt.target.value);
        setSongName(evt.target.value)
    }
        
    const uploadSong = () => {

        
        if(song && selectedImage && songName && hashtags.length != 0) {
            // const uploadTask = ref(`songs/artistSongs/${song.name}`).put(song);
            
            const storageRefSong = ref(storage,`users/${currentUser.email}/songs/${songName}`);
            const uploadTaskSong = uploadBytesResumable(storageRefSong, song);

            
            uploadTaskSong.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            }, (error) => {
                // Handle unsuccessful uploads
                console.log('Upload failed');
            }, 
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTaskSong.snapshot.ref).then((songDownloadURL) => {
                    console.log('File available at', songDownloadURL);

                    const storageRefImage = ref(storage,`users/${currentUser.email}/songImages/${songName}`);
                    const uploadTaskImage = uploadBytesResumable(storageRefImage, image);
        
                    uploadTaskImage.on('state_changed', (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    }, (error) => {
                        // Handle unsuccessful uploads
                        console.log('Upload failed');
                    }, 
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        navigate("/upload/redirect")
                        getDownloadURL(uploadTaskImage.snapshot.ref).then((imageDownloadURL) => {
                            console.log('File available at', imageDownloadURL);
                            const userTracksDirectory = collection(getFirestore(), "users", currentUser.uid, "tracks")
                            const userDirectory = doc(getFirestore(), "users", currentUser.uid)
                            // let searchCollection = database.collection("explore").document("search").collection("data")
                            
                            addDoc(collection(getFirestore(), "songs"), {
                                "artist": username,
                                "artist_id" : currentUser.uid,
                                "comments" : 0,
                                "clip_start_time" : loopStartTime,
                                "full_song_link" : songDownloadURL,
                                "hashtags" : hashtags,
                                "id" : "",
                                "image_link" : imageDownloadURL,
                                "likes" : 0,
                                "listens" : 0,
                                "shares" : 0,
                                "song_name" : songName,
                                "timestamp" : Date()
                                
                            }).then((songSnap) => {
                                updateDoc(songSnap, {
                                    "id": songSnap.id
                                }).then(()=> {
                                    updateDoc(userDirectory, {
                                        "isArtist": true
                                    }).then(() =>{
                                        addDoc(userTracksDirectory, {
                                            "id" : songSnap.id 
                                        }).then(() => {
                                            for (let i = 0; i < hashtags.length(); i++) {
                                                addDoc(doc(getFirestore(), "explore", "hashtags", hashtags[i]), {
                                                    "id" : songSnap.id
                                                })
                                            }
                                        })
                                    })
                                })
                            })                             
                        });
                    });
                });
            });


        }else if (image == null){ 
            setErrorImage(true)
            if(song == null){
                setErrorSong(true)
            }

        }else if(song == null){
            setErrorSong(true)

        }
        
    }

    // Add event listener to play button

    document.addEventListener("DOMContentLoaded", function() {
        // Get the element and add the event listener
        const playButton = document.getElementById("playButton");
        playButton.addEventListener("click", function() {
            audioRef.current.play();

        });

        // Add event listener to pause button
        const pauseButton = document.getElementById("pauseButton");
        pauseButton.addEventListener("click", function() {
            audioRef.current.pause();

        });

        // Add event listener to seek bar
        const seekBar = document.getElementById("seekBar");
        seekBar.addEventListener("change", function() {
        const time = audioRef.current.duration * (seekBar.value / 100);
        audioRef.current.currentTime = time;
        });

        // Update the seek bar as the audio plays
        audioRef.current.addEventListener("timeupdate", function() {

        const value = (100 / audioRef.current.duration) * audioRef.current.currentTime;
        seekBar.value = value;
        });
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        // loadSong()
        // // }
        console.log("Webplayer page")
    }, [])
    
    function handleTimeUpdate() {
        if (audioRef.current != null) {
            setCurrentTime(audioRef.current.currentTime);

        }
    }
    
    function handleLoadedMetadata() {
        if (audioRef.current != null) {

            setDuration(audioRef.current.duration);
        }
    }

    const handleSliderMouseDown = () => {
        setPrevAudioState(audioRef.current.paused ? 'paused' : 'playing');
        setIsDragging(true);
        audioRef.current.pause();
    };

    const handleSliderMouseUp = () => {
        setIsDragging(false);
        if (prevAudioState === 'playing') {
            audioRef.current.play();
        }
      };
      
      

    function handleSliderChange(event) {
        const newTime = event.target.value;
        console.log("changes");
        console.log("changes current: " + (newTime));
        console.log("changes duration: " + audioRef.current.duration);

        if (newTime >= audioRef.current.duration - 30) {
            audioRef.current.currentTime = audioRef.current.duration - 30;
            setCurrentTime(audioRef.current.duration - 30);

        }else{
            audioRef.current.currentTime = newTime;
            setCurrentTime(event.target.value);

        }
        
        const maxStartTime = audioRef.current.duration - 30;
        setLoopStartTime(Math.min(newTime, maxStartTime));
      
    }

    // function fadeOutImage(image) {
    //     if (image) { // check if img exists

    //         image.style.opacity = 1;
    //         // Fade out the image over 1 second
    //         const fadeOutDuration = 1000;
    //         const fadeOutInterval = 10;
    //         const fadeOutStep = 1 / (fadeOutDuration / fadeOutInterval);
    //         let fadeOutCounter = 0;
    //         image.style.display = "block"

    //         const fadeOutIntervalId = setInterval(() => {
    //         if (fadeOutCounter >= 1) {
    //             clearInterval(fadeOutIntervalId);
    //             image.style.display = "none"; // Hide the image when the fade-out is complete
    //         } else {
    //             fadeOutCounter += fadeOutStep;
    //             image.style.opacity = 1 - fadeOutCounter;
    //         }
    //         }, fadeOutInterval);
    //     }
    // }
      

    const toggleAudio = () => {
        if (audioRef.current != null) {
            if (playing) {
                audioRef.current.pause()
                setPlaying(false)
                audioStatusIcon.src = HomePlayButton
                // fadeOutImage(audioStatusIcon)
                imageDisk.classList.add('paused');

            }else{
                console.log("Audio is determining" + audioRef.current)
                audioRef.current.play().then(function() {
                    setPlaying(true)
                    audioStatusIcon.src = HomePauseButton
                    // fadeOutImage(audioStatusIcon)
                    imageDisk.classList.add('playing');
                    imageDisk.classList.remove('paused');

                    // Automatic playback started!
                    console.log("Audio is playing")
            
                }).catch(function(error) {
                    setPlaying(false)
                    console.log("play" + error)
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
                });
            }
        }
    }

    // const setLoopStart = (time) => {
    //     setLoopStartTime(time);
    // };

    // const handleStopLoop = () => {
    //     setLoopStartTime(0);
    // };

    useEffect(() => {
        let loopTimeout;
        if (audioRef.current && !audioRef.current.paused && loopStartTime > 0) {
          const currentTime = audioRef.current.currentTime;
          const timeSinceLoopStart = currentTime - loopStartTime;
          const remainingLoopTime = 30 - (timeSinceLoopStart % 30);
          loopTimeout = setTimeout(() => {
            audioRef.current.currentTime = loopStartTime;
            loopTimeout = setTimeout(() => {
              audioRef.current.play();
            }, 0);
          }, remainingLoopTime * 1000);
        }
        return () => clearTimeout(loopTimeout);
    }, [audioRef.current?.currentTime, loopStartTime]);  
      
    
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.round(time % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
      
    
    useEffect(() => {
        window.scrollTo(0, 0);
        // // 
        var newAudio = document.getElementById("myAudio");

        if (!playing) {
            newAudio.pause()
            // setPlaying(false)

        }else{
            console.log("Audio is determining" + audioRef.current)
            newAudio.load()

            newAudio.play().then(function() {
                setPlaying(true)

                // Automatic playback started!
                console.log("Audio is playing")
        
            }).catch(function(error) {
                // setPlaying(false)

                console.log("fetch: " + error)
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
            });
        }
    console.log("reset")
    }, [selectedAudio])

    // useEffect(() => {

    //     if (image != null) {
    //         songImage.src = selectedImage
    //     }

    // }, [image])

    
    return (
        <div id= "Join-Affiliate-Page">
            <header className="Soundbar">

                <div id= "Home-Controls">

                    {/* <Link to={"/"} style={{ textDecoration: 'none' }} id= "Logo-Box">

                        <img src={SoundbarLogo} id= "Soundbar-Logo" alt="Logo"></img>
                    </Link> */}

                </div>

            </header>

            <header id= "Join-Affiliate-Header">

                <div id= "Join-Affiliate-Title">Upload</div>
                <div id= "Join-Affiliate-Direction" className= "PrimaryText">Submit files below</div>
                <img src={Underline} id= "Underline" alt="TextUnderline"></img>

            </header>

            <header id= "Soundbar-Upload-Page">

                {/* <h1 className= "PrimaryText"> Currently under maintenance...</h1> */}
                {/* <form > */}
                <div id= "Text-Boxes">


                    <Card>
                        <Card.Body>
                        {/* <Form id="Text-Boxes" onSubmit={handleSubmit}> */}
                        <Form>

                            <Form.Group id="name">
                            <Form.Control
                                type="firstName"
                                style={{ border: "none" }}
                                className="UploadTextBox UploadText"
                                // ref={fullNameRef}
                                onChange={handleName}
                                placeholder="Song name"
                                required
                            />
                            </Form.Group>



                            {/* <Button disabled= {loading} id= "Sign-Up-Create-Button" className= "PrimaryBackground" type= "submit">Sign Up</Button> */}
                        </Form>
                        </Card.Body>
                    </Card>
                </div>


                <div id= "Upload-Box">

                    <div id= "Music-Box-Content">

                        Song Image

                        <input onChange={handleImage} accept="image/*" type= "file" id="uploadPhotoButton" style={{display: 'none'}}/>
                        
                        <label className="UploadButton" for="uploadPhotoButton">{image != null ? image.name :"Add File"}</label>
                        
                        {errorImage && "Please upload an image"}

                    </div>
                </div>

                <div id= "Upload-Box">

                    <div id= "Music-Box-Content">

                        Song File

                        <input onChange={handleSong} type= "file" id= "uploadSongButton" style={{display: 'none'}}/>
                        <label className="UploadButton" for="uploadSongButton">{song != null ? song.name : "Add File"}</label>

                        {errorSong && "Please upload a song"}

                    </div>
                </div>

                <Select classNamePrefix="selectMulti " isMulti value={selectedOption} onChange={handleSelectChange} options={options} className= "UploadSelectBox" id="selectMulti">
                    {/* {Add.map((address, key) => (
                    <option value={key}>{address}</option>
                    ))} */}
                </Select>

                <div id="uploadHashtagText">Select up to 3 hashtags*</div>
                
                {song && <div>

                <div id= "uploadTimeContainer">
                    <div id= "uploadCurrentTime">{formatTime(currentTime)}</div><div id= "audioDurationTime">{formatTime(duration)}</div>

                </div>

                <input
                id= "uploadRange"
                type="range"
                min={0}
                max={audioRef.current.duration}
                value={currentTime}
                onChange={handleSliderChange}
                onMouseDown={handleSliderMouseDown} 
                onMouseUp={handleSliderMouseUp} 
                />

                <div id="uploadCliptimeText">Select 30 second clip start time*</div>
                </div>}
                <button onClick= {() => uploadSong} id= "Upload-Button" className= "PrimaryBackground" >Upload</button>

            </header>

            {/* {console.log("songdetails: " + song["full_song_link"])} */}
            <audio id="myAudio" preload="auto" ref={audioRef} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata}>
                <source src={selectedAudio} type="audio/mpeg"/>
            </audio>

            <div id= "Upload-container">
                {image == null ? <div id="uploadPlayerImage" />: <img id="uploadPlayerImage" src={selectedImage} onClick= {toggleAudio}/>}
                {/* <img id="audioStatusIcon" src={HomePauseButton}/> */}

                <div id= "uploadHorizontalStack">
                    <div id= "uploadInfoStack">
                            <div id= "infoStackInner">
                                <img className="profile-image" src={profileImage}/>
                                <div id= "textStack">
                                <div id="artistName">@{username}</div>
                                <div id="uploadSongName">{songName}</div>
                            </div>
                        </div>

                        <div id= "uploadButtonsStack">
                            <div className= "buttonStack">
                                <img id= "likeIcon" src ={SocialsLikeButton}/>

                                <div id= "likeCount" className= "buttonIcon">
                                    0
                                </div>
                
                            </div>

                            <div className= "buttonStack">
                                <img id= "commentIcon" src ={SocialsCommentButton}/>

                                <div id= "commentCount" className= "buttonIcon">
                                    0

                                </div>
                
                            </div>

                            <div className= "buttonStack">
                                <img id= "shareIcon" src ={SocialsShareButton}/>

                                <div id= "shareCount" className= "buttonIcon">
                                    0

                                </div>
                
                            </div>

                            {/* <div className= "buttonStack">
                                <div id= "repostIcon">

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <img id="tabBar" src={tabBar}/>

            </div>


        </div>
    );
}
    
