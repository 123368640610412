import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import Underline from '../../Svg/Underline.png';

import '../../Css/CreateAccountPage.css';
import '../../Css/AffiliateApply.css';

import { BrowserRouter as Router, Switch, Route, Link, useNavigate} from "react-router-dom";

import Footer from '../Components/Footer';
import Updates from '../Components/Updates';
import { doc, setDoc, getFirestore, updateDoc, getDoc, addDoc, collection} from "@firebase/firestore"; 
import {Card, Button, Form, Alert} from 'react-bootstrap'
import React, {useRef, useState} from 'react';
import { useEffect } from 'react';

export default function SupportPage() {

    const nameRef = useRef()
    const emailRef = useRef()
    const messageRef = useRef()
    const history = useNavigate()

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
     }, []);

    async function handleSubmit(e) {
        e.preventDefault()

        
        setError("")
        setLoading(true)
        
        addDoc(collection(getFirestore(), "support"), {
            
            firstName: nameRef.current.value ,
            emailRef: emailRef.current.value ,
            messageRef: messageRef.current.value

        });
        history("/")

        

        setLoading(false)
    }

    return (
        <>
        <div id= "Join-Affiliate-Page">
            <header className="Soundbar">

                <div id= "Home-Controls"> 

                    {/* <Link to={"/"} style={{ textDecoration: 'none' }} id= "Logo-Box">

                        <img src={SoundbarLogo} id= "Soundbar-Logo" alt="Logo"></img>
                    </Link> */}
                </div>
            </header>

            <header id= "Join-Affiliate-Header">

                <div id= "Join-Affiliate-Title">Support</div>
                <div id= "Join-Affiliate-Direction" className= "PrimaryText">Contact Us</div>
                <img src={Underline} id= "Underline" alt="TextUnderline"></img>

            </header>

            <header id= "Soundbar-Affiliate-Apply">

                <Form id= "Text-Boxes" onSubmit= {handleSubmit}>
                    
                    <Form.Group>
                        
                        <Form.Control type="text" style={{border: 'none'}} className= "Text-Box Info-Page-Text" ref={nameRef} placeholder= "Name" required/>
                    </Form.Group>

                    <Form.Group>

                        <Form.Control type="text" style={{border: 'none'}} className= "Text-Box Message-Page-Text" ref={emailRef} placeholder= "Email" required/>
                    </Form.Group>

                    <Form.Group>

                        <Form.Control type="text" style={{border: 'none'}} className= "Text-Box Message-Page-Text" ref={messageRef} placeholder= "Ask us anything!" required/>

                    </Form.Group>

                    <Button  id= "Sign-Up-Create-Button" className= "PrimaryBackground" type= "submit">Send</Button>

                </Form>

           
            </header>
        
        </div>
        <Footer/>

        </>
    );
}
    
