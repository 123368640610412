import "../../Css/CreateAccountPage.css";
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Sidebar from "../Components/Sidebar";

import { Card, Button, Form, Alert } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import CreateAccount from "../Components/CreateAccount";
import { useAuth } from "../Context/AuthContext";
import { doc, setDoc, getFirestore, updateDoc, getDoc, addDoc, collection, update} from "firebase/firestore"; 
import Underline from '../../Svg/Underline.png';

export function EditProfile() {
    const nameRef = useRef();
    const usernameRef = useRef();
    const emailRef = useRef();
    const bioRef = useRef();
    const {currentUser} = useAuth()
    const {updateEmail} = useAuth()
    const {username} = useAuth()
    const {fullName} = useAuth()
    const {bio} = useAuth()
    const { profileImage } = useAuth();
    const { updateProfileInfo } = useAuth();
    const { uploadProfileImage } = useAuth();
    const { checkUsername } = useAuth();

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const handleImageChange = (event) => {
        setImageFile(event.target.files[0])
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
    };
  

    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");

    const [loading, setLoading] = useState(false);

    const history = useNavigate();

  async function handleSubmit(e) {
        e.preventDefault()

        var uploadName = nameRef.current.value
        var uploadBio = bioRef.current.value
        var uploadUsername = usernameRef.current.value

        if (nameRef.current.value == ""){
          uploadName = fullName
        }

        if (bioRef.current.value == ""){
          uploadBio = bio
        } 

        if (usernameRef.current.value == ""){
          uploadUsername = username
        } 


        if (usernameRef.current.value == ""){
            usernameRef.current.value = username

            updateProfileInfo({
                full_name: uploadName,
                username: uploadUsername,
                bio: uploadBio
            })

            if (imageFile != null) {
                uploadProfileImage(imageFile)
                setLoading(false)

                history("/profile")
            }else{
                setLoading(false)
                history("/profile")
            }


        
        }else{

            if (checkUsername) {
                console.log("Email changed- Name: " + nameRef.current.value);
                console.log("Email changed- Username: " + usernameRef.current.value);
                console.log("Email changed- Bio: " + bioRef.current.value);
                console.log("Email - Name: " + uploadName);
                console.log("Email - Username: " + uploadUsername);
                console.log("Email - Bio: " + uploadBio);

                updateProfileInfo({
                    full_name: uploadName,
                    username: uploadUsername,
                    bio: uploadBio
                })

                if (imageFile != null) {
                    uploadProfileImage(imageFile)
                    history("/profile")
                    setLoading(false)

                }else{

                    history("/profile")
                    setLoading(false)
                }
            
            }   
        }
    }

  return (
    <>
      <div id= "EditProfileLayout">
      <Sidebar />

        <header id= "Join-Affiliate-Header">

            <div id= "Join-Affiliate-Direction" className= "PrimaryText">Personal Information</div>
            <img src={Underline} id= "Underline" alt="TextUnderline"></img>
            {/* <input id="upload-image" type="file"/> */}
            {selectedImage ? (
                <img src={selectedImage} alt="Selected" id="editProfilePicture"/>
            ) : (
                <label htmlFor="image-input">
                    <img src={profileImage} id="editProfilePicture" />
                </label>
            )}
            <input
                id="image-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />

        </header>

        <div>
          <Form id="EditProfileBox" onSubmit={handleSubmit}>

            <Form.Group>
            <div className= "editTitle">Name</div>

              <Form.Control
                type="name"
                style={{ border: "none" }}
                className="Edit-Box"
                ref={nameRef}
                placeholder={fullName}
                
              />
            </Form.Group>

            <Form.Group>
            <div className= "editTitle">Username</div>

              <Form.Control
                type="username"
                ref={usernameRef}
                style={{ border: "none" }}
                className="Edit-Box"
                placeholder={username}
                
              />
            </Form.Group>

            {usernameError && (
              <Alert variant="danger" id="Error" className="PrimaryText">
                {usernameError}
              </Alert>
            )}
            
            <Form.Group>
            <div className= "editTitle">Email</div>

              <Form.Control
                type="email"
                ref={emailRef}
                style={{ border: "none" }}
                className="Edit-Box"
                placeholder={currentUser.email}
                
              />
            </Form.Group>

            {emailError && (
              <Alert variant="danger" id="Error" className="PrimaryText">
                {emailError}
              </Alert>
            )}

            <Form.Group>
            <div className= "editTitle">Bio</div>

              <Form.Control
                type="bio"
                ref={bioRef}
                style={{ border: "none" }}
                className="EditBio-Box"
                placeholder={bio}
                
              />
            </Form.Group>

            <Button
      
              disabled={loading}
              style={{ border: "none" }}
              className="SubmitProfileEdit"
              type="submit"
            >
            Update
                
   
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
