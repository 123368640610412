import "../../Css/CreateAccountPage.css";

import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import { Card, Button, Form, Alert } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { useAuth } from "../Context/AuthContext";

// import { doc, setDoc, getFirestore } from "firebase/firestore";

import { doc, setDoc } from "@firebase/firestore";
import { firestore } from '../../firebase';

export default function CreateAccountForm() {
  const firstNameRef = useRef("");
  const lastNameRef = useRef("");
  const usernameRef = useRef("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const rePasswordRef = useRef("");
  const { signup } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const { currentUser, logout } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== rePasswordRef.current.value) {
      console.log("Passwords do not match");
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await setDoc(doc(firestore, "users", currentUser.uid), {
        bio: "",
        bio_link: "",
        cover_link: "",
        followers_count: 0,
        following_count: 0,
        full_name: firstNameRef + lastNameRef,
        id: currentUser.uid,
        likes_count: 0,
        listens: 0,
        profile_link: "",
        username: usernameRef.toLowerCase(),
        phone: 0,
        gender: "",
        birthday: "",
      });

      await signup(emailRef.current.value, passwordRef.current.value);

      history("/signup/redirect");
    } catch {
      if (passwordRef.current.value.length <= 6) {
        console.log("Password is too short");
        return setError("Password is too short");
      } else {
        setError("Failed to create an account, Try again later");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Form id="Text-Boxes" onSubmit={handleSubmit}>
            <Form.Group id="firstName">
              <Form.Control
                type="firstName"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={firstNameRef}
                placeholder="First Name"
                required
              />
            </Form.Group>

            <Form.Group id="lastName">
              <Form.Control
                type="lastName"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={lastNameRef}
                placeholder="Last Name"
                required
              />
            </Form.Group>

            <Form.Group id="username">
              <Form.Control
                type="username"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={usernameRef}
                placeholder="Username"
                required
              />
            </Form.Group>

            <Form.Group id="email">
              <Form.Control
                type="email"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={emailRef}
                placeholder="Email"
                required
              />
            </Form.Group>

            <Form.Group id="password">
              <Form.Control
                type="password"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={passwordRef}
                placeholder="Password"
                required
              />
            </Form.Group>

            <Form.Group id="rePassword">
              <Form.Control
                type="password"
                style={{ border: "none" }}
                className="Text-Box Info-Page-Text"
                ref={rePasswordRef}
                placeholder="Re-Enter Password"
                required
              />
            </Form.Group>

            {error && (
              <Alert variant="danger" id="Error" className="PrimaryText">
                {error}
              </Alert>
            )}

            <Button
              disabled={loading}
              style={{ border: "none" }}
              className="SubmitButtonLogin"
              type="submit"
            >
              <p className="SubmitButtonLoginText">Create Account</p>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
