import "../../Css/HomePage.css";
import HomePlayButton from "../../Svg/HomePlayButton.svg";
import HomePauseButton from "../../Svg/HomePauseButton.svg";

import { AuthProvider, useAuth } from "../Context/AuthContext";
import React, { useContext, useState, useEffect, useRef } from "react";

const SongContext = React.createContext();

export function useSongControls() {
  return useContext(SongContext);
}

export function SongProvider({ children }) {
  const { getArtist } = useAuth();
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const [artist, setArtist] = useState({
    id: "",
    profile_link: "",
    username: "",
    fullName: "",
    followersCount: 0,
    followingCount: 0,
    bio: "",
  });

  const [currentSong, setCurrentSong] = useState({
    artist: "",
    artist_id: "",
    caption: "",
    clip_start_time: "",
    comments: 0,
    full_song_link: "",
    hastags: [""],
    id: "",
    image_link: "",
    likes: 0,
    listens: 0,
    shares: 0,
    song_name: "",
    timeStamp: Date.now,
  });

  const [songIndex, setSongIndex] = useState(0);

  const [songs, setSongs] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  useEffect(() => {
    console.log("Item tossed");
    getArtist(currentSong.artist_id)
      .then((result) => {
        console.log("artistFetched");

        setArtist(result);
      })
      .catch((error) => {
        console.log(error);
        // handle the error
      });
  }, [currentSong]);

  useEffect(() => {
    // if (songs != []) {
    console.log("songs.length");

    // }
  }, [songs]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     // //
  //         var newAudio = document.getElementById("myAudio");

  //     // if (!playing) {
  //     //     newAudio.pause()
  //     //     // setPlaying(false)

  //     // }else{
  //         console.log("Audio is determining" + audioRef.current)
  //         newAudio.load()
  //         if (songImage != null) {
  //             profileImage.src = currentSong.image_link
  //             console.log("Image Loaded")

  //             // setProfileImage()
  //         }else{
  //             console.log("Image Error")

  //         }

  //         newAudio.play().then(function() {
  //             setPlaying(true)

  //             // Automatic playback started!
  //             console.log("Audio is playing")

  //         }).catch(function(error) {
  //             // setPlaying(false)

  //             console.log("fetch: " + error)
  //         // Automatic playback failed.
  //         // Show a UI element to let the user manually start playback.
  //         });
  //     // }
  // console.log("reset")
  // }, [song])

  // async function getArtist(artistID) {
  // }

  function skipTrack() {
    
    setSongIndex(songIndex + 1);
    setCurrentSong(songs[songIndex + 1]);
     
  }

  function lastTrack() {
    setSongIndex(songIndex - 1);
    setCurrentSong(songs[songIndex - 1]);
  };

  function resetSongs (){
    setSongIndex(0);
    setCurrentSong(songs[0]);
  }

  const value = {
    skipTrack,
    lastTrack,
    artist,
    audioRef,
    setSongIndex,
    setPlaying,
    songIndex,
    songs,
    resetSongs,
    // currentTime,
    // duration,
    playing,
    currentSong,
    setSongs,
    setCurrentSong,
  };

  return <SongContext.Provider value={value}>{children}</SongContext.Provider>;
}
