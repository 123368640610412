import '../../Css/CreateAccountPage.css';
import SoundbarLogo from '../../Svg/SoundbarLogo.svg';

import SocialsLikeButton from '../../Svg/SocialsLikeButton.svg';
import SocialsCommentButton from '../../Svg/SocialsCommentButton.svg';
import SocialsShareButton from '../../Svg/SocialsShareButton.svg';
import HomePlayButton from '../../Svg/HomePlayButton.svg';
import HomePauseButton from '../../Svg/HomePauseButton.svg';

import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Card, Button, Form} from 'react-bootstrap'
import React, {useRef, useState} from 'react';
import CreateAccount from '../Components/CreateAccount';
import { Container } from 'react-bootstrap';
import Login from '../Components/Login';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { AuthProvider, getSong , useAuth} from '../Context/AuthContext';
import { doc, setDoc, getFirestore, updateDoc, getDoc, getDocs, collection, orderBy, limit, query} from "@firebase/firestore"; 
import WebPlayer from "../Components/WebPlayer"
import AudioHander from '../Context/AudioHander'

export default function WebPlayerPage() {
    // const [playing, toggle, automaticPlayback] = AudioHander(url);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);  
    const [playing, setPlaying] = useState(true);
    const songImage = document.getElementById("webPlayerImage");
    const profileImage = document.querySelector('.profile-image');
    const audioStatusIcon = document.getElementById('audioStatusIcon');
    const [rotation, setRotation] = useState(0);
    
    const { getArtist } = useAuth()

    const audioRef = useRef(null);

    const [song, setSong] = useState({
        "artist": "",
        "artist_id" : "",
        "clip_start_time" : 0,
        "comments" : 0,
        "full_song_link" : "",
        "hashtags" : [],
        "id" : "",
        "likes" : 0,
        "listens" : 0,
        "shares" : 0,
        "song_name" : "",
        "image_link" : ""

    })

    const [profile, setProfile] = useState({
        "profileImage": "",
        "username" : "",
        "fullName" : "",
        "followersCount" : 0,
        "followingCount" : 0,
        "bio" : ""
    })


    // Add event listener to play button

    document.addEventListener("DOMContentLoaded", function() {
        // Get the element and add the event listener
        const playButton = document.getElementById("playButton");
        playButton.addEventListener("click", function() {
            audioRef.current.play();

        });

        // Add event listener to pause button
        const pauseButton = document.getElementById("pauseButton");
        pauseButton.addEventListener("click", function() {
            audioRef.current.pause();

        });

        // Add event listener to seek bar
        const seekBar = document.getElementById("seekBar");
        seekBar.addEventListener("change", function() {
        const time = audioRef.current.duration * (seekBar.value / 100);
        audioRef.current.currentTime = time;
        });

        // Update the seek bar as the audio plays
        audioRef.current.addEventListener("timeupdate", function() {

        const value = (100 / audioRef.current.duration) * audioRef.current.currentTime;
        seekBar.value = value;
        });
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        loadSong()
        // // }
        console.log("Webplayer page")
    }, [])
    
    function loadSong(){
        const songPath = (window.location.pathname).split("/")
        const songID = songPath[3]
        console.log("songID: " + songID)

        getSong(songID)

    }

    function handleTimeUpdate() {
        if (audioRef.current != null) {
            setCurrentTime(audioRef.current.currentTime);

        }
    }
    
    function handleLoadedMetadata() {
        if (audioRef.current != null) {

            setDuration(audioRef.current.duration);
        }
    }

    function handleSliderChange(event) {
        audioRef.current.currentTime = event.target.value;
        setCurrentTime(event.target.value);
    }

    function fadeOutImage(image) {
        if (image) { // check if img exists

            image.style.opacity = 1;
            // Fade out the image over 1 second
            const fadeOutDuration = 1000;
            const fadeOutInterval = 10;
            const fadeOutStep = 1 / (fadeOutDuration / fadeOutInterval);
            let fadeOutCounter = 0;
            image.style.display = "block"

            const fadeOutIntervalId = setInterval(() => {
            if (fadeOutCounter >= 1) {
                clearInterval(fadeOutIntervalId);
                image.style.display = "none"; // Hide the image when the fade-out is complete
            } else {
                fadeOutCounter += fadeOutStep;
                image.style.opacity = 1 - fadeOutCounter;
            }
            }, fadeOutInterval);
        }
    }
      

    const toggleAudio = () => {
        if (audioRef.current != null) {
            if (playing) {
                audioRef.current.pause()
                setPlaying(false)
                audioStatusIcon.src = HomePlayButton
                fadeOutImage(audioStatusIcon)
                profileImage.classList.add('paused');

            }else{
                console.log("Audio is determining" + audioRef.current)
                audioRef.current.play().then(function() {
                    setPlaying(true)
                    audioStatusIcon.src = HomePauseButton
                    fadeOutImage(audioStatusIcon)
                    profileImage.classList.add('playing');
                    profileImage.classList.remove('paused');

                    // Automatic playback started!
                    console.log("Audio is playing")
            
                }).catch(function(error) {
                    setPlaying(false)
                    console.log("play" + error)
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
                });
            }
        }
    }
    
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.round(time % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
      
    
    useEffect(() => {
        window.scrollTo(0, 0);
        // // 
        var newAudio = document.getElementById("myAudio");

        if (!playing) {
            newAudio.pause()
            // setPlaying(false)

        }else{
            console.log("Audio is determining" + audioRef.current)
            newAudio.load()
            if (songImage != null) {
                songImage.src = song.image_link
                console.log("Image Loaded")

                setProfileImage()
            }else{
                console.log("Image Error")

            }

            newAudio.play().then(function() {
                setPlaying(true)

                // Automatic playback started!
                console.log("Audio is playing")
        
            }).catch(function(error) {
                // setPlaying(false)

                console.log("fetch: " + error)
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
            });
        }
    console.log("reset")
    }, [song])

    useEffect(() => {

        if (profileImage != null) {
            profileImage.src = profile.profileImage
            console.log(profile)
            console.log(song.artist_id)

            console.log("Profile fetched")
        }

        
    }, [profile])

    async function setProfileImage() {
        await getArtist(song.artist_id).then((result) => {
            setProfile(result)
            // do something with the result
          }).catch((error) => {
              console.log(error)
            // handle the error
          });
    }
    async function getSong(songID) {

        const songSnap = getDoc(doc(getFirestore(), "songs", songID))
        console.log("Fetching song " + songID)

        await songSnap.then(value => {  
            console.log("Fetched song" + songID)

            const artist = value.get('artist')
            const artist_id = value.get('artist_id')
            const clip_start_time = value.get('clip_start_time')
            const comments = value.get('comments')
            const full_song_link = value.get('full_song_link')
            const hashtags = value.get('hashtags')
            const id = value.get('id')
            const likes = value.get('likes')
            const listens = value.get('listens')
            const shares = value.get('shares')
            const song_name = value.get('song_name')
            const image_link = value.get('image_link')

            const newProfile = {
                "artist": artist,
                "artist_id" : artist_id,
                "clip_start_time" : clip_start_time,
                "comments" : comments,
                "full_song_link" : full_song_link,
                "hashtags" : hashtags,
                "id" : id,
                "likes" : likes,
                "listens" : listens,
                "shares" : shares,
                "song_name" : song_name,
                "image_link" : image_link
            }

            console.log("newprofile: " + image_link)
            setSong(newProfile)
        }).catch(err => {
        console.log("Song fetch failed" + new Error(err))

        })
    }

    return (
        <div>
            {console.log("songdetails: " + song["full_song_link"])}
            <audio id="myAudio" preload="auto" ref={audioRef} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata}>
                <source src={song["full_song_link"]} type="audio/mpeg"/>
            </audio>

            <div id= "webPlayer-container">
                <img id="webPlayerImage" src="" onClick= {toggleAudio}/>
                <img id="audioStatusIcon" src={HomePauseButton}/>

                <div id= "horizontalStack">
                    <div id= "infoStack">
                            <div id= "infoStackInner">
                                <img className="profile-image" src=""/>
                                <div id= "textStack">
                                <div id="artistName">@{profile.username}</div>
                                <div id="songName">{song.song_name}</div>
                            </div>
                        </div>

                        <div id= "buttonsStack">
                            <div className= "buttonStack">
                                <img id= "likeIcon" src ={SocialsLikeButton}/>

                                <div id= "likeCount" className= "buttonIcon">
                                    {song.likes}
                                </div>
                
                            </div>
                        </div>


                    </div>

                        {/* <div className= "buttonStack">
                            <img id= "commentIcon" src ={SocialsCommentButton} />

                            <div id= "commentCount" className= "buttonIcon">
                                {song.comments}

                            </div>
            
                        </div>

                        <div className= "buttonStack">
                            <img id= "shareIcon" src ={SocialsShareButton}/>

                            <div id= "shareCount" className= "buttonIcon">
                                {song.shares}

                            </div>
            
                        </div>

                        <div className= "buttonStack">
                            <div id= "repostIcon">

                            </div>
                        </div> */}
                </div>
                

                <input
                id="WebplayerSlider"
                type="range"
                min={0}
                max={duration}
                value={currentTime}
                onChange={handleSliderChange}
                />

                <div id= "timeContainer">
                    <div id= "audioCurrentTime">{formatTime(currentTime)}</div><div id= "audioDurationTime">{formatTime(duration)}</div>

                </div>

            </div>
            {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
            {/* <WebPlayer url= {song["full_song_link"]}/> */}
        </div>
    );
}
