import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import Underline from '../../Svg/Underline.png';

import '../../Css/CreateAccountPage.css';
import '../../Css/AffiliateApply.css';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate} from "react-router-dom";
import Menu from '../Components/Menu';

import CreateAccount from '../Components/CreateAccount';
import React, {useRef, useState} from 'react';
import AffiliateApply from '../Components/AffiliateApply';

export default function AffiliateSignUpPage() {

    return (
        <div id= "Join-Affiliate-Page">
            <header id= "Join-Affiliate-Header">

                <div id= "Join-Affiliate-Title">Apply</div>
                <div id= "Join-Affiliate-Direction" className= "PrimaryText">Submit information below</div>
                <img src={Underline} id= "Underline" alt="TextUnderline"></img>
            </header>

            <AffiliateApply/>
            <Menu/>

        </div>
    );
}
    
