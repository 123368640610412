import "../../Css/ProfilePage.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import React, { useRef } from "react";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { useEffect, useState, useCallback } from "react";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import {
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  orderBy,
  limit,
  query,
} from "@firebase/firestore";
import { ProfileSongCell } from "../Components/ProfileSongCell";
import  {useSongControls} from "../Context/SongContext";

import sortIcon from "../../Svg/sortIcon.svg";
import playIcon from "../../Svg/playIcon.svg";
import pauseIcon from "../../Svg/pauseIcon.svg";
import likeIcon from "../../Svg/likeIcon.svg";
import commentIcon from "../../Svg/commentIcon.svg";
import shareIcon from "../../Svg/shareIcon.svg";
import listensIcon from "../../Svg/listensIcon.svg";
import moreIcon from "../../Svg/moreIcon.svg";

export default function UserProfilePage() {
  const [artist, setArtist] = useState({
    id: "",
    profile_link: "",
    username: "",
    fullName: "",
    followersCount: 0,
    followingCount: 0,
    bio: "",
  });
  const [tracks, setTracks] = useState([]);
  const [reposts, setReposts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { setCurrentSong } = useSongControls();
  const { setSongs } = useSongControls();
  const { setSongIndex } = useSongControls();

  const [isTrackDisplay, setIsTrackDisplay] = useState(true);
  const [underlinePosition, setUnderlinePosition] = useState({ x: 0, y: 0 });

  const trackDisplay = tracks.map((song, index) => {
    return (
      <div className="ProfileSongCell" onClick= {() => setSong(song, index, tracks)}>
      {console.log(song)}

      <div id="SongCellMainDetails">
        <div
          id="songImage"
          style={{ backgroundImage: `url('${song.image_link}')` }}
        >
          <div id="songImageDetails">
            <img src={listensIcon} id="listensIcon" />
            <h id="listenCount">{intToString(song.listens)}</h>
          </div>
        </div>

        <div id="songTextInfo">
          <div id="songName">{song.song_name}</div>
          <div id="by">by</div>
          <div id="songArtist">{song.artist}</div>
        </div>
      </div>

      <div id="songControls">
        <div id="songControlButtons">
          <div id="songItems">
            <div className="songItemDetail">
              <img src={likeIcon} id="songItemIcon" />
              <h className="songItemValue">{intToString(song.likes)}</h>
            </div>

            <div className="songItemDetail">
              <img src={commentIcon} id="songItemIcon" />
              <h className="songItemValue">
                {intToString(song.comments)}
              </h>
            </div>

            <div className="songItemDetail">
              <img src={shareIcon} id="songItemIcon" />
              <h className="songItemValue">{intToString(song.shares)}</h>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  });

  const repostDisplay = reposts.map((item, index) => {
    return (
      <div className="ProfileSongCell" onClick= {() => setSong(item, index, reposts)}>
      {/* {console.log(song)} */}

      <div id="SongCellMainDetails">
        <div
          id="songImage"
          style={{ backgroundImage: `url('${item.image_link}')` }}
        >
          <div id="songImageDetails">
            <img src={listensIcon} id="listensIcon" />
            <h id="listenCount">{intToString(item.listens)}</h>
          </div>
        </div>

        <div id="songTextInfo">
          <div id="songName">{item.song_name}</div>
          <div id="by">by</div>
          <div id="songArtist">{item.artist}</div>
        </div>
      </div>

      <div id="songControls">
        <div id="songControlButtons">
          <div id="songItems">
            <div className="songItemDetail">
              <img src={likeIcon} id="songItemIcon" />
              <h className="songItemValue">{intToString(item.likes)}</h>
            </div>

            <div className="songItemDetail">
              <img src={commentIcon} id="songItemIcon" />
              <h className="songItemValue">
                {intToString(item.comments)}
              </h>
            </div>

            <div className="songItemDetail">
              <img src={shareIcon} id="songItemIcon" />
              <h className="songItemValue">{intToString(item.shares)}</h>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
    // var tracksRender = tracks.map((item, index) => {

  //     return <ProfileSongCell song= {item}/>
  // })

  function intToString(value) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  async function fetchTracks() {
    setIsLoading(true);
    const userTracks = collection(getFirestore(), "users", artist.id, "tracks");

    await getDocs(userTracks).then((response) => {
      // console.log("Responses" + response.docs[0].get("id"))

      const songData = response.docs.map((doc) => doc.id);

      console.log("Get promosises");
      console.log(songData[0]);

      const songPromises = songData.map(async (songId) => {
        console.log("SongID: " + songId);
        const songValue = await getDoc(doc(getFirestore(), "songs", songId));
        return {
          artist: songValue.get("artist"),
          artist_id: songValue.get("artist_id"),
          caption: songValue.get("caption"),
          clip_start_time: songValue.get("clip_start_time"),
          comments: songValue.get("comments"),
          full_song_link: songValue.get("full_song_link"),
          hashtags: songValue.get("hashtags"),
          id: songValue.get("id"),
          image_link: songValue.get("image_link"),
          likes: songValue.get("likes"),
          listens: songValue.get("listens"),
          shares: songValue.get("shares"),
          song_name: songValue.get("song_name"),
          timestamp: songValue.get("timestamp"),
        };
      });

      Promise.all(songPromises).then((songs) => {
        console.log("Songs: " + songs);

        setTracks(songs);
      });

      setIsLoading(false);

      console.log(isLoading);
    });
  }

  async function fetchReposts() {
    setIsLoading(true);
    const userReposts = collection(
      getFirestore(),
      "users",
      artist.id,
      "reposts"
    );

    await getDocs(userReposts).then((response) => {
      // console.log("Responses" + response.docs[0].get("id"))

      const songData = response.docs.map((doc) => doc.id);

      console.log("Get promosises");
      console.log(songData[0]);

      const songPromises = songData.map(async (songId) => {
        console.log("SongID: " + songId);
        const songValue = await getDoc(doc(getFirestore(), "songs", songId));
        return {
          artist: songValue.get("artist"),
          artist_id: songValue.get("artist_id"),
          caption: songValue.get("caption"),
          clip_start_time: songValue.get("clip_start_time"),
          comments: songValue.get("comments"),
          full_song_link: songValue.get("full_song_link"),
          hashtags: songValue.get("hashtags"),
          id: songValue.get("id"),
          image_link: songValue.get("image_link"),
          likes: songValue.get("likes"),
          listens: songValue.get("listens"),
          shares: songValue.get("shares"),
          song_name: songValue.get("song_name"),
          timestamp: songValue.get("timestamp"),
        };
      });

      Promise.all(songPromises).then((songs) => {
        console.log("Songs: " + songs);

        setReposts(songs);
      });

      setIsLoading(false);

      console.log(isLoading);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchTracks();
    fetchReposts();
  }, [artist]);

  useEffect(() => {
    loadArtist();
  }, [location]);

  function loadArtist() {
    const songPath = window.location.pathname.split("/");
    const artistID = songPath[1];
    const userSnap = getDoc(doc(getFirestore(), "users", artistID));

    // console.log("Email: ", user.email)
    userSnap.then((value) => {
      const id = value.get("id");
      const username = value.get("username");
      const profile_link = value.get("profile_link");
      const full_name = value.get("full_name");
      const followers_count = value.get("followers_count");
      const following_count = value.get("following_count");
      const listens = value.get("listens");
      const bio = value.get("bio");

      setArtist({
        id: id,
        username: username,
        profile_link: profile_link,
        fullName: full_name,
        followers: followers_count,
        following: following_count,
        listens: listens,
        bio: bio,
      });
    });

    // getSong(songID)
  }

  function toggleTrackDisplay(e) {
    // const button = e.target;
    const trackButton = document.getElementById("trackItem");

    // const position = button.getBoundingClientRect();
    const positionLeft = trackButton.offsetLeft;
    const positionTop = trackButton.offsetTop;

    console.log("Button position:", positionLeft, positionTop);

    setIsTrackDisplay(true);
    setUnderlinePosition({ x: positionLeft, y: positionTop });
  }

  function toggleRepostsDisplay(e) {
    // const button = e.target;
    const repostButton = document.getElementById("repostItem");

    // const position = button.getBoundingClientRect();
    const positionLeft = repostButton.offsetLeft;
    const positionTop = repostButton.offsetTop;

    console.log("Button position:", positionLeft, positionTop);

    setIsTrackDisplay(false);
    setUnderlinePosition({ x: positionLeft, y: positionTop });
  }

  function setSong(clickedSong, index, type) {
    console.log("SongLoading");
    
    setCurrentSong(clickedSong)
    setSongs(type)
    setSongIndex(index)
  }


  // useEffect(() => {
  //     console.log('App comp value:', tracks);

  // }, [tracks]);

  return (
    <div>

      <div className="ProfileLayout">
        <Sidebar />

        <div id="ProfilePage">
          <div id="ProfileTop">
            <div id="ProfileDetails">
              <img src={artist.profile_link} id="profilePicture" />
              <div id="ProfileMainDetails">
                <div className="ProfileMainTopDetails">
                  <div id="Username">@{artist.username}</div>
                  <div id="Fullname">{artist.fullName}</div>

                  <Link
                    className="profileButton"
                    to="/editProfile"
                    style={{ textDecoration: "none" }}
                  >
                    Follow
                  </Link>
                </div>
                <div id="profileInfo">
                  <div className="infoSection">
                    <div className="infoValue">{artist.followers}</div>
                    <div className="infoTitle">Followers</div>
                  </div>

                  <div className="infoSection">
                    <div className="infoValue">{artist.following}</div>
                    <div className="infoTitle">Following</div>
                  </div>

                  <div className="infoSection">
                    <div className="infoValue">{artist.listens}</div>
                    <div className="infoTitle">Listens</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div id="bio">{artist.bio}</div>
            </div>

            {/* <div className= "lineSeperator"/> */}
          </div>
          <div id="songOptions">
            <div id="toggleType">
              <div id="toggleTypes">
                <div className="toggleItemTextBox">
                  <div
                    className="toggleItem"
                    id="trackItem"
                    onClick={(e) => toggleTrackDisplay(e)}
                  >
                    Tracks
                  </div>
                </div>
                <div className="toggleItemTextBox">
                  <div
                    className="toggleItem"
                    id="repostItem"
                    onClick={(e) => toggleRepostsDisplay(e)}
                  >
                    Reposts
                  </div>
                </div>
              </div>

              {isTrackDisplay ? (
                <div id="underlines">
                  <div
                    className="optionHighlight"
                    style={{ backgroundColor: "white" }}
                  />
                  <div className="optionHighlight" />
                </div>
              ) : (
                <div id="underlines">
                  <div className="optionHighlight" />
                  <div
                    className="optionHighlight"
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              )}

              {/* <div id= "profileButtons"> */}
            </div>
            <Link
              id="uploadButton"
              to="/upload"
              style={{ textDecoration: "none" }}
            >
              <p className="UploadProfile">Upload</p>
            </Link>
            {/* </div> */}

            {/* <div id= "sortButton">
                        <div id="sortTitle">Sort</div>
                        <img src= {sortIcon} id= "sortIcon"></img>
                    </div> */}
          </div>

          {isTrackDisplay ? trackDisplay : repostDisplay}

          {/* <QuickLinks/> */}
        </div>
      </div>
    </div>
  );
}
