import '../../Css/HomePage.css';
import HomePlayButton from '../../Svg/HomePlayButton.svg';
import HomePauseButton from '../../Svg/HomePauseButton.svg';
import {SongProvider, useSongControls} from "../Context/SongContext";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import React, { useContext, useState, useEffect, useRef } from "react"
import SocialsLikeButton from '../../Svg/SocialsLikeButton.svg';
import fastFoward from '../../Svg/SongControls/fastFoward.svg';
import rewind from '../../Svg/SongControls/rewind.svg';
import play from '../../Svg/SongControls/play.svg';
import pause from '../../Svg/SongControls/pause.svg';
import muteVolume from '../../Svg/SongControls/muteVolume.svg';
import volume from '../../Svg/SongControls/volume.svg';

import artistPage from '../../Svg/SongControls/artistPage.svg';
import { func } from 'joi';



export default function SongControls() {

    const songImage = document.getElementById("webPlayerImage");
    const profileImage = document.querySelector('.profile-image');
    const audioStatusIcon = document.getElementById('ControlsTogglePlay');
    const { audioRef } = useSongControls();
    const { currentSong } = useSongControls();
    const { setPlaying } = useSongControls();
    const { playing } = useSongControls();
    const { artist } = useSongControls();
    const { skipTrack } = useSongControls();
    const { lastTrack } = useSongControls();
    const { songIndex } = useSongControls();
    const { songs } = useSongControls();
    const { resetSongs } = useSongControls();
    var newAudio = document.getElementById("myAudio");



    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);  


    useEffect( () => {

        if (newAudio != null) {
            newAudio.load()
            
            console.log("Song Loaded")

            if (songImage != null) {
                profileImage.src = currentSong.image_link
                console.log("Image Loaded")

                // setProfileImage()
            }else{
                console.log("Image Error")

            }

            newAudio.play().then(function() {
                setPlaying(true)

                // Automatic playback started!
                console.log("Audio is playing")
        
            }).catch(function(error) {
                // setPlaying(false)

                console.log("fetch: " + error)
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
            });
        }


    }, [currentSong])

    useEffect( () => {

    }, [artist])

    useEffect( () => {

    }, [playing])

    useEffect( () => {
        // console.log("songLength: " + songs.length())
    }, [songs])

    useEffect( () => {
        // console.log("songLength: " + songs.length())
    }, [newAudio])



    useEffect(() => {
        if (audioRef.current != null) {
            if (audioRef.current.currentTime == audioRef.current.duration) {
                fastFowardButton()
            }
        }
    }, [audioRef.current?.currentTime]);  



    document.addEventListener("DOMContentLoaded", function() {
        // Get the element and add the event listener


        // Add event listener to seek bar
        const seekBar = document.getElementById("seekBar");
        seekBar.addEventListener("change", function() {
        const time = audioRef.current.duration * (seekBar.value / 100);
        audioRef.current.currentTime = time;
        });

        // Update the seek bar as the audio plays
        audioRef.current.addEventListener("timeupdate", function() {

        const value = (100 / audioRef.current.duration) * audioRef.current.currentTime;
        seekBar.value = value;
        });

    });

    function handleTimeUpdate() {
        if (audioRef.current != null) {
            setCurrentTime(audioRef.current.currentTime);

        }
    }
    
    function handleLoadedMetadata() {
        if (audioRef.current != null) {

            setDuration(audioRef.current.duration);
        }
    }

    function handleSliderChange(event) {
        audioRef.current.currentTime = event.target.value;
        
        setCurrentTime(event.target.value);
    }

    function handleVolumeChange(event) {

        newAudio.volume = event.target.value / 100;
    }


    function fastFowardButton(){ 
        console.log("songIndex: " + songIndex)

        if (songIndex < songs.length - 1) {
            skipTrack()
    
        }else{
            resetSongs()

        }
       
    };

    function rewindButton() {

        if (audioRef.current.currentTime <= 1 && songIndex >= 1) {
            lastTrack()
        }else{
            audioRef.current.currentTime = 0
        }

    }

    // function playButton() {
    //     audioRef.current.play();

    // }

    // // Add event listener to pause button
    // function pauseButton() {
    //     audioRef.current.pause();

    // }

    
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.round(time % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    const muteAudio = () => {
        console.log("Mute!");
        newAudio.volume = 0
    }

    const toggleAudio = () => {
        if (audioRef.current != null) {
            if (playing) {
                audioRef.current.pause()
                setPlaying(false)
                audioStatusIcon.src = play
                // profileImage.classList.add('paused');

            }else{
                console.log("Audio is determining" + audioRef.current)
                audioRef.current.play().then(function() {
                    setPlaying(true)
                    audioStatusIcon.src = pause
                    // profileImage.classList.add('playing');
                    // profileImage.classList.remove('paused');

                    // Automatic playback started!
                    console.log("Audio is playing")
            
                }).catch(function(error) {
                    setPlaying(false)
                    console.log("play" + error)
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
                });
            }
        }
    }
    return (
        songs.length != 1 ? 

            <div id= "SongControls">
                <audio id="myAudio" preload="auto" ref={audioRef} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata}>
                    <source src={currentSong["full_song_link"]} type="audio/mpeg"/>
                </audio>
                <div id= "ControlsInfoStack">

                    {duration != 0 && 
                    <div id= "infoStackInner">
                        <img id="songControlImage" src={currentSong.image_link}/>
                        <div id= "textStack">
                            <div id="ControlsSongName">{currentSong.song_name}</div>
                            <div id="ControlsArtistName">@{currentSong.artist}</div>
                        </div>
                    </div>}

                    <div id="SongControlsVMiddle">
                            <input
                        type="range"
                        min={0}
                        max={duration}
                        value={currentTime}
                        onChange={handleSliderChange}
                        />
                        <div id= "SongControlsMiddleStack">
                            <div className= "ControlsTime">{formatTime(currentTime)}</div>
                            <div id="AudioControlStack">
                                <img id="audioTrackRewind" src={rewind} onClick= {() => rewindButton()}/>
                                {playing ? <img id="ControlsTogglePlay" src={pause} onClick= {() => toggleAudio()}/> : <img id="ControlsTogglePlay" src={play} onClick= {() => toggleAudio()}/>}
                                <img id="audioTrackFastfowrd" src={fastFoward} onClick= {() => fastFowardButton()}/>

                            </div>
                            <div className= "ControlsTime">{formatTime(duration)}</div>

                        </div>
                        

                        
                    </div>


                    {duration != 0 && 
                    <div id= "ControlsSocialsButtons">
                        {/* <img id= "ArtistPageButton" src ={artistPage}/> */}
                        {newAudio.volume !== 0 ?
                        <div onClick={()=> muteAudio()}>
                            <img id="volumeIcon" src={volume}/>
                        </div> :
                        <div id="volumeIcon">
                            <img src={muteVolume}/>
                        </div>
                        }
                        
                        <input type="range" min="0" max="100" value={newAudio.volume} id="volume-slider" onChange={handleVolumeChange}/>

                        <div className= "SongConrolsButtonStack">

                            <img id= "ControlslikeIcon" src ={SocialsLikeButton}/>
                            <div id= "likeCount" className= "buttonIcon">
                                {currentSong.likes}
                            </div>
                
                        </div>
                    </div>}
                </div>
            </div>
        : <div/>
    );
}
  