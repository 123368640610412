import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import "../../Css/Sidebar.css";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import listensIcon from "../../Svg/listensIcon.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import { SongProvider, useSongControls } from "../Context/SongContext";
import SongControls from "../Components/SongControls";
import { PromotionProvider, usePromotions } from "../Context/PromotionContex";

import PlayIconWeb from "../../Svg/PlayIconWeb.png";

// import { AntDesign } from 'react-web-vector-icons';

export default function Sidebar() {
  const { suggestedUsersAccounts } = usePromotions();
  const { songsForYouPromotion } = usePromotions();
  const { fetchAllSongs } = usePromotions();
  const { fetchAllArtists } = usePromotions();

  const searchRef = useRef();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { suggestedAccounts } = useAuth();
  const { showcase } = useAuth();

  const history = useNavigate();
  // const { setCurrentSong } = useSongControls();
  const { setCurrentSong } = useSongControls();
  const { setSongs } = useSongControls();
  const { setSongIndex } = useSongControls();

  useEffect(() => {}, [suggestedAccounts]);

  function intToString(value) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  function navigateArtistPage(artist) {
    console.log("Item iD:" + artist.id);
    history(`/${artist.id}/`);
  }

  function setSong(clickedSong, index) {
    console.log("SongLoading");

    setCurrentSong(clickedSong);
    setSongs(fetchAllSongs)
    setSongIndex(index)
  }

  //TEMPORARY DATE SOURCE
  const artistCell = fetchAllArtists.map((item) => {
    return (
      <div
        className="ArtistCellSidebar"
        onClick={() => navigateArtistPage(item)}
      >
        <img id="artistImage" src={item.profile_link} />
        <div id="artistDetials">
          <div className="username">@{item.username}</div>
          <div className="fullName">{item.fullName}</div>
        </div>
      </div>
    );
  });

  //TEMPORARY DATE SOURCE
  const songCell = fetchAllSongs.map((song, index) => {


    return (
      <div className="SidebarSongCell" key= {index} onClick={() => setSong(song, index)}>
        <div className="HomeSongSidebar">
          <div className="HomeSongLeft">
            <div id="songImageBox">
              <div
                id="songImageSidebar"
                style={{ backgroundImage: `url('${song.image_link}')` }}
              >
                <div className="topHitsSongInfo">
                  <img src={listensIcon} className="listensIconText" />
                  <h className="listenCountText">{song.listens}</h>
                </div>
              </div>
            </div>

            <div className="songDetials">
              <div className="songNameSidebar">{song.song_name}</div>
              <div className="artistNameSidebar">@{song.artist}</div>
            </div>
          </div>
          <img className="playIconTopHits" src={PlayIconWeb}></img>
        </div>
      </div>
    );
  });

  return (
    <AuthProvider>
      <div id="Sidebar">
        <div className="SidebarAllignContent">
          <div className="sidebarSection">
            <div className="sidebarTitle">Suggested Accounts</div>
            <div className="sidebarContent">{artistCell}</div>
          </div>

          <div className="sidebarSection">
            <div className="sidebarTitle">Songs for you</div>
            <div className="sidebarContent">{songCell}</div>
          </div>

          {/* <div className="sidebarSection">
          <div className="sidebarTitle">Recently Listened</div>
          <div className="sidebarContent">{songCell}</div>
        </div> */}
        </div>
      </div>
      
    </AuthProvider>
  );
}
