import "../../Css/AffiliateApplicationPage.css";

import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey, SurveyThemeProvider } from "survey-react-ui";
import { Validators } from "survey-react-ui";

import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { useRef } from "react";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";

export default function AffiliateApplicationPage() {
  const { usernameExists, saveAffiliateData, sendEmail } = useAuth();
  const navigate = useNavigate();

  async function validateUsername(survey, { data, errors, complete }) {
    const username = data["username"];
    if (!username) {
      complete();
      return;
    }

    const exists = await usernameExists(username);
    if (!exists) {
      errors["username"] = "Invalid username. Please enter a valid username.";
    }
    complete();
  }

  const surveyJson = {
    pages: [
      {
        name: "page1",
        elements: [
          {
            name: "username",
            type: "text",
            title: "What is your Soundbar account username",
            isRequired: true,
            requiredErrorText: "Please enter your Soundbar username",
          },
          {
            name: "referralCodeUsed",
            type: "text",
            title: "What is your referral code if applicable",
          },
        ],
      },
      {
        name: "page2",
        elements: [
          {
            type: "text",
            name: "email",
            title: "What is your email",
            isRequired: true,
            requiredErrorText: "Please enter your email",
          },
          {
            type: "comment",
            name: "hearAboutSoundbar",
            title: "How did you hear about Soundbar (optional)",
          },
        ],
      },
      {
        name: "page3",
        elements: [
          {
            type: "text",
            name: "instagramHandle",
            title: "What is your instagram handle (optional)",
          },
          {
            type: "text",
            name: "spotifyLink",
            title: "Link to spotify profile (optional)",
          },
          {
            type: "text",
            name: "soundcloudLink",
            title: "Link to soundcloud profile (optional)",
          },
        ],
      },
      {
        name: "page3",
        elements: [
          {
            type: "comment",
            name: "artistbiography",
            title: "Write a short biography about yourself and your music!",
            isRequired: true,
            requiredErrorText: "Tell everyone about yourself!",
          },
        ],
      },
    ],
  };

  const survey = new Model(surveyJson);

  function sendEmailFunction(surveyData, emailData) {
    sendEmail(
      surveyData.email,
      emailData.subject,
      "d-e253a873b0e446ceadfee3c08b6fcae0", // Replace with your SendGrid template ID
      {
        name: emailData.name,
        subject: emailData.subject,
        // Add other dynamic data as needed
      }
    )
      .then((result) => {
        console.log("Email sent:", result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleSurveyComplete(survey, options) {
    const surveyData = survey.data;
    const emailData = {
      name: "Recipient Name",
      subject: "Email Subject",
      // Add other email data as needed
    };
    saveAffiliateData(surveyData);
    sendEmailFunction(surveyData, emailData);
    navigate("/affiliate/apply/applicationsuccess", { state: surveyData });
  }

  survey.onServerValidateQuestions.add(validateUsername);
  survey.onComplete.add(handleSurveyComplete);

  return (
    <>
      <BrowserView>
        <div className="AffiliateApplicationPage">
          <Survey className="AffiliateApplicationSurvey" model={survey} />;
        </div>
      </BrowserView>
      <MobileView>
        <div className="AffiliateApplicationPageMobile">
          <Survey className="AffiliateApplicationSurvey" model={survey} />;
        </div>
      </MobileView>
    </>
  );
}
