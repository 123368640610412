import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import Underline from "../../Svg/Underline.png";
import BulletIcon from "../../Svg/BulletIcon.svg";
import iPhones from "../../Svg/iPhones.png";

import betaFeatures from "../../Svg/AffiliatePage/betaFeatures.svg";
import betaFeaturesSelceted from "../../Svg/AffiliatePage/betaFeaturesSelceted.svg";
import explorePage from "../../Svg/AffiliatePage/explorePage.svg";
import explorePageSelected from "../../Svg/AffiliatePage/explorePageSelected.svg";
import freeMerch from "../../Svg/AffiliatePage/freeMerch.svg";
import freeMerchSelected from "../../Svg/AffiliatePage/freeMerchSelected.svg";
import hotBars from "../../Svg/AffiliatePage/hotBars.svg";
import hotBarsSelected from "../../Svg/AffiliatePage/hotBarsSelected.svg";
import instagramPromo from "../../Svg/AffiliatePage/instagramPromo.svg";
import instagramPromoSelected from "../../Svg/AffiliatePage/instagramPromoSelected.svg";
import playlistAdded from "../../Svg/AffiliatePage/playlistAdded.svg";
import playlistAddedSelected from "../../Svg/AffiliatePage/playlistAddedSelected.svg";
import soundbarLive from "../../Svg/AffiliatePage/soundbarLive.svg";
import soundbarLiveSelected from "../../Svg/AffiliatePage/soundbarLiveSelected.svg";

import "../../Css/CreateAccountPage.css";
import "../../Css/AffiliateApply.css";
import "../../Css/HomePage.css";
import "../../Css/AffiliatePage.css";

import Footer from "../Components/Footer";
import { React, useEffect } from "react";
import { PromotionProvider, usePromotions } from "../Context/PromotionContex";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

export default function AffiliatePage() {
  const { affiliateAccounts } = usePromotions();
  const history = useNavigate();

  const affiliateAccountsMap = affiliateAccounts.map((item) => {
    {
      console.log("affilateImages: " + item.profile_link);
    }
    return (
      <div
        className="ArtistAccount"
        onClick={() => navigateArtistPage(item)}
        style={{
          backgroundImage: `url(${item.profile_link})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          zIndex: 1,
        }}
      >
        {/* <img src={} className="ArtistCardImage"></img> */}
        <div id="ArtistInfo">
          <p className="AffiliateName">{item.username}</p>
          <p className="AffiliateUsername">{item.bio}</p>
        </div>
      </div>
    );
  });

  function navigateArtistPage(artist) {
    console.log("Item iD:" + artist.id);
    history(`/${artist.id}/`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="AffiliatePage">
      <div className="AffiliateBoxAffiliate">
        <div className="AffiliateBoxAffiliateShadow">
          <p className="AffiliateBoxATitle">
            Become a Soundbar Affiliate Today
          </p>
          <div className="ApplyAffiliateAButton">
            <Link
              to={"/affiliate/apply"}
              style={{ textDecoration: "none" }}
              className="MenuButton"
            >
              <p className="ApplyAffiliateText">APPLY</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="AffiliateFeatures">
        <p className="AffiliateTitle">Soundbar Affiliate Program</p>
        <p className="AffiliateDesc">
          We help you become the artist you dreamed of and connect you with your
          community community
        </p>
        <div className="AffiliateContent">
          <div className="AffiliateRow">
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={hotBars} />
              <div className="AffiliateText">
                Being pushed on our “Hot Bars” feed
              </div>
            </div>
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={playlistAdded} />
              <div className="AffiliateText">
                Placed on our Soundbar playlist’s
              </div>
            </div>
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={explorePage} />
              <div className="AffiliateText">
                Being promoted on our explore page
              </div>
            </div>
          </div>
          <div className="AffiliateRow">
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={soundbarLive} />
              <div className="AffiliateText">
                Hosting “Soundbar Live” events at clubs for you to perform
                at(depending on your location)
              </div>
            </div>
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={instagramPromo} />
              <div className="AffiliateText">Promoted on our instagram</div>
            </div>
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={betaFeatures} />
              <div className="AffiliateText">
                Beta just for unreleased features
              </div>
            </div>
          </div>
          <div className="AffiliateRow">
            <div className="AffiliateItem">
              <img className="AffiliateImg" src={freeMerch} />
              <div className="AffiliateText">
                Sent free merch during our drops(no shipping fees)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="OurAffiliates">
        <p className="OurAffiliatesTitle">Soundbar Affiliates</p>
        <div className="OurAffiliatesContent">{affiliateAccountsMap}</div>
      </div>
      <Footer />
    </div>
  );
}
