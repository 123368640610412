import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
// import fowardIcon from '../../Svg/fowardIcon.svg';
// import backwardIcon from '../../Svg/backwardIcon.svg';

import { BrowserView, MobileView } from "react-device-detect";

import SwipeIcon from "../../Svg/SwipeIcon.png";
import HomePhone from "../../Svg/iPhoneWireframes/HomeWebWireframe.png";

import TapIcon from "../../Svg/TapIcon.png";
import HomeFullPhone from "../../Svg/HomeFullPhone.png";

import ShareIcon from "../../Svg/ShareIcon.png";
import SharePhone from "../../Svg/SharePhone.png";

import SearchIcon from "../../Svg/SearchIcon.png";
import ExplorePhone from "../../Svg/ExplorePhone.png";

import PlaylistIcon from "../../Svg/PlaylistIcon.png";
import PlaylistPhone from "../../Svg/PlaylistPhone.png";

import MessageIcon from "../../Svg/MessageIcon.png";
import MessagePhone from "../../Svg/MessagePhone.png";

import listensIcon from "../../Svg/listensIcon.svg";
import ContentImage from "../../Svg/TestContentImage.PNG";

import Sidebar from "../Components/Sidebar";
import Share from "../Components/shareComponent";

import iPhones from "../../Svg/iPhones.png";
import moreButton from "../../Svg/moreButton.svg";

import HomePhoneMobile from "../../Svg/iPhoneWireframes/WebsiteHomeWireframeMOBILE.png";
import ExplorePhoneMobile from "../../Svg/iPhoneWireframes/WebsiteExploreWireframeMOBILE.png";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "../../Css/HomePage.css";
import "../../Css/HomePageWireframes.css";
import "../../Css/slider-animations.css";

import React, { useState, useEffect } from "react";

import Footer from "../Components/Footer";

import Updates from "../Components/Updates";

import FadeInWhenVisible from "../Components/Animations/FadeInWhenVisible";
import TransitionToLeft from "../Components/Animations/TransitionToLeft";
import TransitionToRight from "../Components/Animations/TransitionToRight";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";

import { AuthProvider, useAuth } from "../Context/AuthContext";
import { PromotionProvider, usePromotions } from "../Context/PromotionContex";
import { ShareProvider, useShare } from "../Context/ShareContext";
import { useSongControls } from "../Context/SongContext";

export default function Home() {
  const { getArtist } = useAuth();
  const { weeklyShowcase } = usePromotions();
  const { topHitsPromotion } = usePromotions();
  const { affiliateAccounts } = usePromotions();

  const { setCurrentSong } = useSongControls();
  const { setSongs } = useSongControls();
  const { setSongIndex } = useSongControls();

  const { displaySharePage } = useShare();
  const { setDisplaySharePage } = useShare();
  const { shareSong } = useShare();
  const { setShareSong } = useShare();
  const history = useNavigate();

  const [artists, setArtists] = useState({
    "": {
      artist_Id: "",
      profileImage: "",
      username: "",
      fullName: "",
      followersCount: 0,
      followingCount: 0,
      bio: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProfiles();
  }, [weeklyShowcase]);

  useEffect(() => {
    console.log(`New artists: ${Object.keys(artists).length}`);
  }, [artists]);

  async function fetchProfile(artist) {
    console.log("Fetching: " + artist);
    return new Promise((resolve, reject) => {
      getArtist(artist)
        .then((result) => {
          for (const key in result) {
            console.log(`${artist} Image: ${key}: ${result[key]}`);
          }

          resolve(result);
        })
        .catch((error) => {
          reject(error);

          // handle the error
        });
    });
  }

  function setSong(clickedSong, index) {
    console.log("SongLoading");

    setCurrentSong(clickedSong);
    setSongs(topHitsPromotion);
    setSongIndex(index);
  }

  function sharePage(song) {
    console.log(song);
    console.log(song);
    console.log(song);
    setShareSong(song);
    setDisplaySharePage(true);
  }

  function navigateArtistPage(artist) {
    console.log("Item iD:" + artist.id);
    history(`/${artist.id}/`);
  }

  async function fetchProfiles() {
    var newProfiles = {};

    for (let i = 0; i < weeklyShowcase.length; i++) {
      console.log("showcase: " + weeklyShowcase[i].artist_id);
      const newProfile = await fetchProfile(weeklyShowcase[i].artist_id);
      newProfiles[newProfile.artist_Id] = newProfile;
    }

    setArtists(newProfiles);
  }

  const affiliateAccountsMap = affiliateAccounts.map((item) => {
    return (
      <div
        className="ArtistAccount"
        style={{
          backgroundImage: `url(${item.profile_link})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          zIndex: 1,
        }}
        onClick={() => navigateArtistPage(item)}
      >
        {/* <img src={} className="ArtistCardImage"></img> */}
        <div className="AffiliteArtistInfo">
          <p className="AffiliateName">{item.username}</p>
          <p className="AffiliateBiography">{item.artist_biography}</p>
        </div>
      </div>
    );
  });

  const topHitsMap = topHitsPromotion.map((song, index) => {
    return (
      <div className="HomeSong" onClick={() => setSong(song, index)}>
        <div className="HomeSongLeft">
          <div id="songImageBox" onClick={() => setSong(song, index)}>
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${song.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">{song.listens}</h>
              </div>
            </div>
          </div>

          <div className="songDetials">
            <div className="songNameTopHits">{song.song_name}</div>
            <div className="artistNameTopHits">{song.artist}</div>
          </div>
        </div>

        <div onClick={() => sharePage(song)}>
          <img className="playIconTopHits" src={moreButton} />
        </div>
      </div>
    );
  });

  // setArtists({ ...artists, artist: result });

  return (
    <div className="HomePage">
      {/* THIS IS THE VIEW IF USER IS ACCESSING FROM A COMPUTER  */}
      <BrowserView>
        <div id="HomePageHStack">
          <Sidebar />
          <Share />
          <div className="HomePageContent">
            <div className="ExploreTopContent">
              <div className="HomeTopContent">
                <div className="HomePageInfoRow">
                  <div className="TopHits">
                    <p className="InfoTitle">Today's Hits</p>
                    <div className="HomeSongs">{topHitsMap}</div>
                  </div>
                  <div className="InfoOne">
                    <p className="InfoTitle">Weekly Showcase</p>
                    <Slider
                      className="slider-wrapper"
                      autoplay={1000}
                      onSlideChange={(event) => console.log(event.slideIndex)}
                    >
                      {weeklyShowcase.map((item, index) => (
                        <div
                          key={index}
                          className="slider-content"
                          style={{
                            background: `url('${item.image_link}') no-repeat center center`,
                          }}
                        >
                          {artists[item.artist_id] ? (
                            <section>
                              <img
                                src={artists[item.artist_id].profileImage}
                                alt={item.artist}
                              />
                              <span>
                                <p className="showcaseSong">{item.song_name}</p>
                                <strong className="showcaseArtist">
                                  {artists[item.artist_id].username}
                                </strong>
                                {/* {item.song_name}{" "}
                            <strong>{artists[item.artist_id].username}</strong> */}
                              </span>
                            </section>
                          ) : (
                            <p>Loading...</p>
                          )}
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="HomePageInfoRowTwo">
                  <div className="SoundbarAffiliates">
                    <p className="InfoTitleAffiliates">Soundbar Affiliates</p>
                    <div className="HomeAffiliateBoxRow">
                      {affiliateAccountsMap}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="YellowBottomHomePage"></div> */}
            <div id="ExplorePage-Content">
              <div id="App-Rows">
                <TransitionToLeft>
                  <FadeInWhenVisible>
                    <div className="App-Row LeftPhone">
                      {/* <FadeInWhenVisible> */}
                      <div className="Phone-background" id="Rightbackground" />

                      <img src={HomePhone} className="Phone" alt="Logo"></img>
                      {/* </FadeInWhenVisible> */}

                      <div className="App-Page-Info" id="LeftbackgroundText">
                        <img
                          src={SwipeIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Swipe on an endless feed of song clips perfectly
                          curated just for you
                        </div>
                      </div>
                    </div>
                  </FadeInWhenVisible>
                </TransitionToLeft>

                <TransitionToRight>
                  <FadeInWhenVisible>
                    <div className="App-Row RightPhone">
                      <div className="App-Page-Info" id="RightbackgroundText">
                        <img
                          src={TapIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Always just one tap away from the full song
                        </div>
                      </div>

                      <img src={HomeFullPhone} className="Phone" alt="Logo" />
                    </div>
                  </FadeInWhenVisible>
                </TransitionToRight>

                <TransitionToLeft>
                  <FadeInWhenVisible>
                    <div className="App-Row LeftPhone">
                      <div className="Phone-background" id="Leftbackground" />

                      {/* <FadeInWhenVisible> */}

                      <img src={SharePhone} className="Phone" alt="Logo"></img>
                      {/* </FadeInWhenVisible> */}

                      <div className="App-Page-Info" id="LeftbackgroundText">
                        <img
                          src={ShareIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Share with Anyone. Anytime, Anywhere
                        </div>
                      </div>
                    </div>
                  </FadeInWhenVisible>
                </TransitionToLeft>

                <TransitionToRight>
                  <FadeInWhenVisible>
                    <div className="App-Row RightPhone">
                      <div className="App-Page-Info" id="RightbackgroundText">
                        <img
                          src={SearchIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Find new songs and trending songs faster than ever
                        </div>
                      </div>

                      <img
                        src={ExplorePhone}
                        className="Phone"
                        alt="Logo"
                      ></img>
                    </div>
                  </FadeInWhenVisible>
                </TransitionToRight>
                <TransitionToLeft>
                  <FadeInWhenVisible>
                    <div className="App-Row LeftPhone">
                      <div className="Phone-background" id="Rightbackground" />

                      {/* <FadeInWhenVisible> */}
                      <img
                        src={PlaylistPhone}
                        className="Phone"
                        alt="Logo"
                      ></img>
                      {/* </FadeInWhenVisible> */}

                      <div className="App-Page-Info" id="LeftbackgroundText">
                        <img
                          src={PlaylistIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Make new playlists with ease
                        </div>
                      </div>
                    </div>
                  </FadeInWhenVisible>
                </TransitionToLeft>

                <TransitionToRight>
                  <FadeInWhenVisible>
                    <div className="App-Row RightPhone">
                      <div className="App-Page-Info" id="RightbackgroundText">
                        <img
                          src={MessageIcon}
                          className="Tap Icon"
                          alt="Logo"
                        ></img>

                        <div className="App-Page-Text">
                          Easily message them to all your friends
                        </div>
                      </div>
                      <img
                        src={MessagePhone}
                        className="Phone"
                        alt="Logo"
                      ></img>
                    </div>
                  </FadeInWhenVisible>
                </TransitionToRight>
              </div>

              {/* <Sidebar/> */}
            </div>
            {/* <Menu /> */}
            {/* <SongControls/> */}
          </div>
        </div>
        <Footer />
      </BrowserView>
      {/* THIS IS THE VIEW IF USER IS ACCESSING FROM A MOBILE DEVICE - NOT SURE ABOUT TABLETS  */}
      <MobileView>
        <div id="MobileHomePage">
          <Share />
          <div className="HomeMobileCarousel">
            <Slider
              className="slider-wrapper-mobile"
              autoplay={1000}
              onSlideChange={(event) => console.log(event.slideIndex)}
            >
              {weeklyShowcase.map((item, index) => (
                <div
                  key={index}
                  className="slider-content"
                  style={{
                    background: `url('${item.image_link}') no-repeat center center`,
                  }}
                >
                  {artists[item.artist_id] ? (
                    <section>
                      <img
                        src={artists[item.artist_id].profileImage}
                        alt={item.artist}
                      />
                      <span>
                        <p className="showcaseSong">{item.song_name}</p>
                        <strong className="showcaseArtist">
                          {artists[item.artist_id].username}
                        </strong>
                        {/* {item.song_name}{" "}
                            <strong>{artists[item.artist_id].username}</strong> */}
                      </span>
                    </section>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              ))}
            </Slider>
          </div>
          <div className="PhoneWireframeSetup">
            <TransitionToRight>
              <div className="LeftYellowBackgroundMobile">
                <div className="LeftYellowBackgroundInnerMobile">
                  <TransitionToLeft>
                    <div className="LeftPhoneRow">
                      <img
                        src={HomePhoneMobile}
                        className="LeftPhoneImageMobile"
                        alt="Logo"
                      ></img>
                    </div>
                  </TransitionToLeft>
                  <TransitionToRight>
                    <div className="RightPhoneRow">
                      <img
                        src={ExplorePhoneMobile}
                        className="RightPhoneImageMobile"
                        alt="Logo"
                      ></img>
                    </div>
                  </TransitionToRight>
                </div>
              </div>
            </TransitionToRight>

            <TransitionToLeft>
              <div className="RightYellowBackgroundMobile">
                <div className="RightYellowBackgroundInnerMobile">
                  <TransitionToRight>
                    <div className="LeftPhoneRow">
                      <img
                        src={HomePhoneMobile}
                        className="LeftPhoneImageMobile"
                        alt="Logo"
                      ></img>
                    </div>
                  </TransitionToRight>
                  <TransitionToLeft>
                    <div className="RightPhoneRow">
                      <img
                        src={ExplorePhoneMobile}
                        className="RightPhoneImageMobile"
                        alt="Logo"
                      ></img>
                    </div>
                  </TransitionToLeft>
                </div>
              </div>
            </TransitionToLeft>
          </div>
          <FadeInWhenVisible>
            <div></div>
          </FadeInWhenVisible>
        </div>
        {/* <SongControls/> */}
        {/* <Footer /> */}
      </MobileView>
    </div>
  );
}

// function SliderItem({ item }) {
//   return (
//     <div
//       className="slider-content"
//       style={{ background: `url('${item.image}') no-repeat center center` }}
//     >
//       <div className="inner">
//         <h1>{item.title}</h1>
//         <p>{item.description}</p>
//         <button>{item.button}</button>
//       </div>
//       <section>
//         <img src={item.userProfile} alt={item.user} />
//         <span>
//           Posted by <strong>{item.user}</strong>
//         </span>
//       </section>
//     </div>
//   );
// }
