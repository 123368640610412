import React, { useEffect, useState } from "react";
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import "../../Css/Share.css";
import LikeIcon from "../../Svg/SharePageAssets/FavoriteShareIcon.png";
import LikedIcon from "../../Svg/SharePageAssets/FavoriteShareIconLiked.png";
import RepostIcon from "../../Svg/SharePageAssets/RepostShareIcon.png";
import ViewArtistIcon from "../../Svg/SharePageAssets/ViewArtistShareIcon.png";
import PlayNextIcon from "../../Svg/SharePageAssets/PlayNextShareIcon.png";
import PlayLastIcon from "../../Svg/SharePageAssets/PlayLastShareIcon.png";
import ReportIcon from "../../Svg/SharePageAssets/ReportShareIcon.png";

import InstaIcon from "../../Svg/SharePageAssets/instaShareIcon.png";
import TikTokIcon from "../../Svg/SharePageAssets/tiktokShareIcon.png";
import FBIcon from "../../Svg/SharePageAssets/fbShareIcon.png";
import CopyLinkIcon from "../../Svg/SharePageAssets/copylinkShareIcon.png";

import { ShareProvider, useShare } from "../Context/ShareContext";

export default function Share(props) {
  const shareOptions = [
    { optionName: "Like", icon: LikeIcon },
    { optionName: "Repost", icon: RepostIcon },
    { optionName: "View Artist", icon: ViewArtistIcon },
    { optionName: "Play Next", icon: PlayNextIcon },
    { optionName: "Play Last", icon: PlayLastIcon },
    { optionName: "Report", icon: ReportIcon },
  ];

  const { displaySharePage } = useShare();
  const { setDisplaySharePage } = useShare();
  const { shareSong } = useShare();

  const handleClickOutside = () => {
    setDisplaySharePage(!setDisplaySharePage);
  };

  const shareOptionsMap = shareOptions.map((item) => {
    return (
      <div lassName="shareOptions">
        <div className="shareFullLine"></div>
        <div className="shareItemFull">
          <img src={item.icon} className="shareItemIcon"></img>
          <p className="shareItem">{item.optionName}</p>
        </div>
      </div>
    );
  });
  console.log(shareSong);
  if (displaySharePage) {
    return (
      <div className="SharePageShadow" onClick={handleClickOutside}>
        <div className="shareComponent">
          <div className="shareTop">
            <div className="ImageAndSongName">
              <img src={shareSong.image_link} className="contentImage"></img>
              <div className="shareText">
                <p className="shareTitleOne">{shareSong.song_name}</p>
                <p className="shareTitleTwo">{shareSong.artist}</p>
              </div>
            </div>
            <div className="shareFullLine"></div>
            <div className="externalShareOptions">
              <div className="externalShareOptionsItem">
                <img
                  src={InstaIcon}
                  className="externalShareOptionsImage"
                ></img>
                <p className="externalShareText">Instagram</p>
              </div>
              <div className="externalShareOptionsItem">
                <img
                  src={TikTokIcon}
                  className="externalShareOptionsImage"
                ></img>
                <p className="externalShareText">TikTok</p>
              </div>
              <div className="externalShareOptionsItem">
                <img src={FBIcon} className="externalShareOptionsImage"></img>
                <p className="externalShareText">Facebook</p>
              </div>
              <div className="externalShareOptionsItem">
                <img
                  src={CopyLinkIcon}
                  className="externalShareOptionsImage"
                ></img>
                <p className="externalShareText">Copy Link</p>
              </div>
            </div>
            <div className="shareFullLine"></div>
          </div>
          <div className="shareOptions">{shareOptionsMap}</div>
        </div>
      </div>
    );
  } else return;
}
