import '../../Css/CreateAccountPage.css';
import SoundbarLogo from '../../Svg/SoundbarLogo.svg';
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Card, Button, Form} from 'react-bootstrap'
import React, {useRef} from 'react';
import CreateAccount from '../Components/CreateAccount';
import { AuthProvider } from '../Context/AuthContext';
import { Container } from 'react-bootstrap';
import Login from '../Components/Login';
import Footer from '../Components/Footer';
import { useEffect } from 'react';

export default function LoginPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
     }, []);

    return (
        <AuthProvider>
            <div id= "Create-Account-Page">
                {/* <header className="Soundbar">

                    <Link to={"/"} style={{ textDecoration: 'none' }} id= "Logo-Box">

                        <img src={SoundbarLogo} id= "Soundbar-Logo" alt="Logo"></img>
                    </Link>
                </header> */}

                <header id= "Soundbar-Sign-Up">
                    <div id= "Soundbar-Sign-Up-Box">
                        <div id= "Sign-Up-Header">

                            <div id= "Login-Title" className= "PrimaryText">Login</div>
                            {/* <div id= "Sign-Up-Direction">Create your account</div> */}
                        </div>
                        <Login/>
                        
                        <Link id= "Login-Direct" className= "" to= "/signup"  style={{ textDecoration: 'none' }}>
                        Need an account? Sign Up
                        </Link>                           
                    </div>
                </header>
                <Footer/>

            </div>

        </AuthProvider>
    );
}
    