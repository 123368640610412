import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion"
import React, { useState, useEffect } from "react";
import { useAnimation } from 'framer-motion';

export default function TransitionToRight({ children }) {
  const controls = useAnimation()
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1 }}
      variants={{
        visible: { x: "3vw"},
        hidden: { x: "0vw"}
      }}
    >
      {children}
    </motion.div>
  );
}