import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
// import fowardIcon from '../../Svg/fowardIcon.svg';
// import backwardIcon from '../../Svg/backwardIcon.svg';

import HitOne from "../../Svg/TopHitsImages/topHitOne.jpg";
import HitTwo from "../../Svg/TopHitsImages/topHitTwo.jpg";
import HitThree from "../../Svg/TopHitsImages/topHitThree.png";
import HitFour from "../../Svg/TopHitsImages/topHitFour.jpg";
import HitFive from "../../Svg/TopHitsImages/topHitFive.jpg";
import HitSix from "../../Svg/TopHitsImages/topHitSix.jpg";

import SwipeIcon from "../../Svg/SwipeIcon.png";
import HomePhone from "../../Svg/HomePhone.png";

import TapIcon from "../../Svg/TapIcon.png";
import HomeFullPhone from "../../Svg/HomeFullPhone.png";

import ShareIcon from "../../Svg/ShareIcon.png";
import SharePhone from "../../Svg/SharePhone.png";

import SearchIcon from "../../Svg/SearchIcon.png";
import ExplorePhone from "../../Svg/ExplorePhone.png";

import PlaylistIcon from "../../Svg/PlaylistIcon.png";
import PlaylistPhone from "../../Svg/PlaylistPhone.png";

import MessageIcon from "../../Svg/MessageIcon.png";
import MessagePhone from "../../Svg/MessagePhone.png";

import AffiliateImage from "../../Svg/AffiliateImage.jpeg";

import PlayIconWeb from "../../Svg/PlayIconWeb.png";

import listensIcon from "../../Svg/listensIcon.svg";
import ContentImage from "../../Svg/TestContentImage.PNG";

import Sidebar from "../Components/Sidebar";

import iPhones from "../../Svg/iPhones.png";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "../../Css/HomePage.css";
import "../../Css/ExplorePage.css";
import "../../Css/slider-animations.css";

import React, { useState, useEffect } from "react";

import Footer from "../Components/Footer";

import Updates from "../Components/Updates";

import FadeInWhenVisible from "../Components/Animations/FadeInWhenVisible";
import TransitionToLeft from "../Components/Animations/TransitionToLeft";
import TransitionToRight from "../Components/Animations/TransitionToRight";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";

import { AuthProvider, useAuth } from "../Context/AuthContext";
import { PromotionProvider, usePromotions } from "../Context/PromotionContex";
import { useSongControls } from "../Context/SongContext";

export default function ExplorePage() {
  const { trendingArtistsPromotion } = usePromotions();
  const { onFirePromotion } = usePromotions();
  const { newMusicPromotion } = usePromotions();

  const { UPANDCOMING_hashtagPromotion } = usePromotions();
  const { MUSIC_hashtagPromotion } = usePromotions();
  const { RAPPER_hashtagPromotion } = usePromotions();

  const { setCurrentSong } = useSongControls();
  const { setSongs } = useSongControls();
  const { setSongIndex } = useSongControls();

  const { affiliateAccounts } = usePromotions();

  const { getArtist } = useAuth();

  const { getExploreSongs } = useAuth();
  const { getExploreArtists } = useAuth();
  const { exploreSongs } = useAuth();

  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchProfiles();
    //getExploreSongs();
    //getExploreArtists();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchProfiles();
  }, [exploreSongs, getExploreArtists]);

  function setSong(clickedSong, index, type) {
    console.log("SongLoading");

    setCurrentSong(clickedSong);
    setSongs(type);
    setSongIndex(index);
  }

  function navigateArtistPage(artist) {
    console.log("Item iD:" + artist.id);
    history(`/${artist.id}/`);
  }

  const onFireSongs = onFirePromotion.map((item, index) => {
    return (
      <div
        className="songExploreCell"
        onClick={() => setSong(item, index, onFirePromotion)}
      >
        <div className="HomeSongLeft">
          <div id="songImageBox">
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${item.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">123</h>
              </div>
            </div>
          </div>
          <div className="songDetials">
            <div className="songNameTopHits">{item.song_name}</div>
            <div className="artistNameTopHits">{item.artist}</div>
          </div>
        </div>
        <img className="playIconTopHits" src={PlayIconWeb}></img>
      </div>
    );
  });

  const trendingArtistRow = trendingArtistsPromotion.map((artist) => {
    return (
      <div
        className="artistExploreCell"
        onClick={() => navigateArtistPage(artist)}
      >
        <img src={artist.profile_link} className="artistExploreImage"></img>
        <p className="artistExploreUsername">{artist.username}</p>
        <p className="artistExploreName">{artist.fullName}</p>
      </div>
    );
  });

  const affiliateArtistsRow = affiliateAccounts.map((artist) => {
    return (
      <div
        className="ArtistAccount"
        style={{
          backgroundImage: `url(${artist.profile_link})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          zIndex: 1,
        }}
        onClick={() => navigateArtistPage(artist)}
      >
        {/* <img src={} className="ArtistCardImage"></img> */}
        <div className="AffiliteArtistInfo">
          <p className="AffiliateName">{artist.username}</p>
          <p className="AffiliateBiography">{artist.artist_biography}</p>
        </div>
      </div>
    );
  });

  const hashtagRowOne = UPANDCOMING_hashtagPromotion.map((song, index) => {
    return (
      <div
        className="songExploreCell"
        onClick={() => setSong(song, index, UPANDCOMING_hashtagPromotion)}
      >
        <div className="HomeSongLeft">
          <div id="songImageBox">
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${song.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">123</h>
              </div>
            </div>
          </div>
          <div className="songDetials">
            <div className="songNameTopHits">{song.song_name}</div>
            <div className="artistNameTopHits">{song.artist}</div>
          </div>
        </div>
        <img className="playIconTopHits" src={PlayIconWeb}></img>
      </div>
    );
  });

  const newMusicRow = newMusicPromotion.map((song, index) => {
    return (
      <div
        className="songExploreCell"
        onClick={() => setSong(song, index, newMusicPromotion)}
      >
        <div className="HomeSongLeft">
          <div id="songImageBox">
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${song.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">123</h>
              </div>
            </div>
          </div>
          <div className="songDetials">
            <div className="songNameTopHits">{song.song_name}</div>
            <div className="artistNameTopHits">{song.artist}</div>
          </div>
        </div>
        <img className="playIconTopHits" src={PlayIconWeb}></img>
      </div>
    );
  });

  const hashtagRowTwo = MUSIC_hashtagPromotion.map((song, index) => {
    return (
      <div
        className="songExploreCell"
        onClick={() => setSong(song, index, MUSIC_hashtagPromotion)}
      >
        <div className="HomeSongLeft">
          <div id="songImageBox">
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${song.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">123</h>
              </div>
            </div>
          </div>
          <div className="songDetials">
            <div className="songNameTopHits">{song.song_name}</div>
            <div className="artistNameTopHits">{song.artist}</div>
          </div>
        </div>
        <img className="playIconTopHits" src={PlayIconWeb}></img>
      </div>
    );
  });

  const hashtagRowThree = RAPPER_hashtagPromotion.map((song, index) => {
    return (
      <div
        className="songExploreCell"
        onClick={() => setSong(song, index, RAPPER_hashtagPromotion)}
      >
        <div className="HomeSongLeft">
          <div id="songImageBox">
            <div
              id="songImageSidebar"
              style={{ backgroundImage: `url('${song.image_link}')` }}
            >
              <div className="topHitsSongInfo">
                <img src={listensIcon} className="listensIconText" />
                <h className="listenCountText">123</h>
              </div>
            </div>
          </div>
          <div className="songDetials">
            <div className="songNameTopHits">{song.song_name}</div>
            <div className="artistNameTopHits">{song.artist}</div>
          </div>
        </div>
        <img className="playIconTopHits" src={PlayIconWeb}></img>
      </div>
    );
  });

  const styles = {
    container: {
      position: "fixed",
    },
  };

  return (
    <div className="ExplorePage">
      <div className="ExploreTopContent">
        <Sidebar className="fixedSidebar" />
        <div className="ExploreContent">
          <div className="ExploreDoubleSongRow">
            <p className="RowTitleBig">On Fire</p>

            <div className="SongRow">{onFireSongs}</div>
          </div>
          <div className="ExploreRow">
            <p className="RowTitleBig">Trending Artists</p>
            <div className="artistRowExplore">{trendingArtistRow}</div>
          </div>
          <div className="ExploreSingleSongRow">
            <p className="RowTitleMedium">#upandcoming</p>
            <div className="SongRow">{hashtagRowOne}</div>
          </div>
          <div className="ExploreDoubleSongRow">
            <p className="RowTitleBig">New Music</p>
            <div className="SongRow">{newMusicRow}</div>
          </div>
          <div className="ExploreRow">
            <p className="RowTitleBig">Soundbar Affiliates</p>
            <div className="artistRowExplore">{affiliateArtistsRow}</div>
          </div>
          <div className="ExploreSingleSongRow">
            <p className="RowTitleMedium">#music</p>
            <div className="SongRow">{hashtagRowTwo}</div>
          </div>
          <div className="ExploreSingleSongRow">
            <p className="RowTitleMedium">#rapper</p>
            <div className="SongRow">{hashtagRowThree}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

// function SliderItem({ item }) {
//   return (
//     <div
//       className="slider-content"
//       style={{ background: `url('${item.image}') no-repeat center center` }}
//     >
//       <div className="inner">
//         <h1>{item.title}</h1>
//         <p>{item.description}</p>
//         <button>{item.button}</button>
//       </div>
//       <section>
//         <img src={item.userProfile} alt={item.user} />
//         <span>
//           Posted by <strong>{item.user}</strong>
//         </span>
//       </section>
//     </div>
//   );
// }
