import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import {
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  where,
  orderBy,
  limit,
  query,
} from "@firebase/firestore";
import axios from "axios";

// import "firebase/compat/auth";
import "firebase/storage"; // <----
import { getStorage } from "@firebase/storage";
import "firebase/firestore";

const PromotionContext = React.createContext();

export function usePromotions() {
  return useContext(PromotionContext);
}

export function PromotionProvider({ children }) {
  const [fetchAllSongs, setFetchAllSongs] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [fetchAllArtists, setFetchAllArtists] = useState([
    {
      artist_Id: "",
      profileImage: "",
      username: "",
      fullName: "",
      followersCount: 0,
      followingCount: 0,
      bio: "",
    },
  ]);

  const [weeklyShowcase, setWeeklyShowcase] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [topHitsPromotion, setTopHitsPromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [onFirePromotion, setOnFirePromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [newMusicPromotion, setNewMusicPromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [songsForYouPromotion, setSongsForYouPromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [suggestedUsersAccounts, setSuggestedUsersAccounts] = useState([
    {
      artist_Id: "",
      profileImage: "",
      username: "",
      fullName: "",
      followersCount: 0,
      followingCount: 0,
      bio: "",
    },
  ]);

  const [affiliateAccounts, setAffiliateAccounts] = useState([
    {
      artist_Id: "",
      profileImage: "",
      username: "",
      fullName: "",
      followersCount: 0,
      followingCount: 0,
      bio: "",
    },
  ]);

  const [trendingArtistsPromotion, setTrendingArtistsPromotion] = useState([
    {
      artist_Id: "",
      profileImage: "",
      username: "",
      fullName: "",
      followersCount: 0,
      followingCount: 0,
      bio: "",
    },
  ]);

  const [UPANDCOMING_hashtagPromotion, set_UPANDCOMING_hashtagPromotion] =
    useState([
      {
        artist: "",
        artist_id: "",
        caption: "",
        clip_start_time: "",
        comments: 0,
        full_song_link: "",
        hastags: [""],
        id: "",
        image_link: "",
        likes: 0,
        listens: 0,
        shares: 0,
        song_name: "",
        timeStamp: Date.now,
      },
    ]);

  const [MUSIC_hashtagPromotion, set_MUSIC_hashtagPromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [RAPPER_hashtagPromotion, set_RAPPER_hashtagPromotion] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      const fetchAllSongsSnap = query(
        collection(getFirestore(), "songs"),
        limit(20)
      );

      getDocs(fetchAllSongsSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setFetchAllSongs(songs);
      });

      const fetchAllArtistsSnap = query(
        collection(getFirestore(), "users"),
        limit(40)
      );

      getDocs(fetchAllArtistsSnap)
        .then((response) => {
          return Promise.all(
            response.docs.map((doc) => {
              const tracksRef = collection(doc.ref, "tracks");
              return getDocs(tracksRef).then((querySnapshot) => {
                console.log(
                  `User document ${doc.id} has tracks subcollection with size ${querySnapshot.size}`
                );
                if (querySnapshot.size > 0) {
                  return {
                    id: doc.id,
                    username: doc.get("username"),
                    profile_link: doc.get("profile_link"),
                    fullName: doc.get("full_name"),
                    followersCount: doc.get("followers_count"),
                    followingCount: doc.get("following_count"),
                    listens: doc.get("listens"),
                    bio: doc.get("bio"),
                  };
                } else {
                  return null;
                }
              });
            })
          );
        })
        .then((artists) => {
          const filteredArtists = artists.filter((artist) => artist !== null);
          console.log(filteredArtists);
          setFetchAllArtists(filteredArtists);
        })
        .catch((error) => {
          console.error("Error fetching user documents:", error);
        });

      const weeklyShowcaseSnap = query(
        collection(getFirestore(), "songs"),
        where("promotion_type", "==", "weeklyshowcase"),
        limit(10)
      );

      getDocs(weeklyShowcaseSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setWeeklyShowcase(songs);
      });

      const topHitsSnap = query(
        collection(getFirestore(), "songs"),
        where("promotion_type", "==", "tophits"),
        limit(10)
      );

      getDocs(topHitsSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setTopHitsPromotion(songs);
      });

      const onFireSnap = query(
        collection(getFirestore(), "songs"),
        where("promotion_type", "==", "onfire"),
        limit(10)
      );

      getDocs(onFireSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setOnFirePromotion(songs);
      });

      const newMusicSnap = query(
        collection(getFirestore(), "songs"),
        orderBy("timestamp", "desc"),
        limit(8)
      );

      getDocs(newMusicSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setNewMusicPromotion(songs);
      });

      const songsForYouSnap = query(
        collection(getFirestore(), "songs"),
        where("promotion_type", "==", "songsforyou"),
        limit(10)
      );

      getDocs(songsForYouSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setSongsForYouPromotion(songs);
      });

      getDocs(songsForYouSnap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        setSongsForYouPromotion(songs);
      });

      const suggestedUsersSnap = query(
        collection(getFirestore(), "users"),
        where("promotion_type", "==", "suggestedaccounts"),
        limit(10)
      );

      getDocs(suggestedUsersSnap).then((response) => {
        const users = response.docs.map((doc) => ({
          id: doc.get("id"),
          username: doc.get("username"),
          profile_link: doc.get("profile_link"),
          fullName: doc.get("full_name"),
          followersCount: doc.get("followers_count"),
          followingCount: doc.get("follow,ing_count"),
          listens: doc.get("listens"),
          bio: doc.get("bio"),
        }));
        setSuggestedUsersAccounts(users);
      });

      const affiliateAccountsSnap = query(
        collection(getFirestore(), "users"),
        where("affiliate", "==", true),
        limit(5)
      );

      getDocs(affiliateAccountsSnap).then((response) => {
        const artists = response.docs.map((doc) => ({
          id: doc.get("id"),
          username: doc.get("username"),
          profile_link: doc.get("profile_link"),
          fullName: doc.get("full_name"),
          followersCount: doc.get("followers_count"),
          followingCount: doc.get("follow,ing_count"),
          listens: doc.get("listens"),
          bio: doc.get("bio"),
          artist_biography: doc.get("artist_biography"),
        }));
        setAffiliateAccounts(artists);
      });

      const trendingArtistsSnap = query(
        collection(getFirestore(), "users"),
        where("promotion_type", "==", "trendingartist"),
        limit(10)
      );

      getDocs(trendingArtistsSnap).then((response) => {
        const users = response.docs.map((doc) => ({
          id: doc.get("id"),
          username: doc.get("username"),
          profile_link: doc.get("profile_link"),
          fullName: doc.get("full_name"),
          followersCount: doc.get("followers_count"),
          followingCount: doc.get("follow,ing_count"),
          listens: doc.get("listens"),
          bio: doc.get("bio"),
        }));
        setTrendingArtistsPromotion(users);
      });

      const UPANDCOMING_hashtag_snap = query(
        collection(getFirestore(), "songs"),
        // where("promotion_type", "==", "hashtag"),
        where("hashtags", "array-contains", "upandcoming"),
        limit(8)
      );

      getDocs(UPANDCOMING_hashtag_snap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        set_UPANDCOMING_hashtagPromotion(songs);
      });

      const MUSIC_hashtag_snap = query(
        collection(getFirestore(), "songs"),
        // where("promotion_type", "==", "hashtag"),
        where("hashtags", "array-contains", "music"),
        limit(8)
      );

      getDocs(MUSIC_hashtag_snap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        set_MUSIC_hashtagPromotion(songs);
      });

      const RAPPER_hashtag_snap = query(
        collection(getFirestore(), "songs"),
        // where("promotion_type", "==", "hashtag"),
        where("hashtags", "array-contains", "rapper"),
        limit(8)
      );

      getDocs(RAPPER_hashtag_snap).then((response) => {
        const songs = response.docs.map((doc) => ({
          artist: doc.get("artist"),
          artist_id: doc.get("artist_id"),
          caption: doc.get("caption"),
          clip_start_time: doc.get("clip_start_time"),
          comments: doc.get("comments"),
          full_song_link: doc.get("full_song_link"),
          hashtags: doc.get("hashtags"),
          id: doc.get("id"),
          image_link: doc.get("image_link"),
          likes: doc.get("likes"),
          listens: doc.get("listens"),
          shares: doc.get("shares"),
          song_name: doc.get("song_name"),
          timestamp: doc.get("timestamp"),
        }));
        // console.log(songs)

        set_RAPPER_hashtagPromotion(songs);
      });

      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const [loading, setLoading] = useState(true);

  const value = {
    songsForYouPromotion,
    newMusicPromotion,
    topHitsPromotion,
    onFirePromotion,
    suggestedUsersAccounts,
    affiliateAccounts,
    trendingArtistsPromotion,
    weeklyShowcase,
    UPANDCOMING_hashtagPromotion,
    MUSIC_hashtagPromotion,
    RAPPER_hashtagPromotion,
    fetchAllSongs,
    fetchAllArtists,
  };

  return (
    <PromotionContext.Provider value={value}>
      {!loading && children}
    </PromotionContext.Provider>
  );
}
