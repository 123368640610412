import React, {useRef, useState} from 'react';
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import "../../Css/HomePage.css";
import MenuDivider from "../../Svg/MenuDivider.svg";
import instagramLogo from "../..//Svg/InstagramIcon.svg";
import linkedInLogo from "../..//Svg/LinkedInIcon.svg";
import { doc, setDoc, getFirestore, updateDoc, getDoc, addDoc, collection} from "@firebase/firestore";
import {Card, Button, Form, Alert} from 'react-bootstrap'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import InstaIcon from "../..//Svg/WebsiteIcons/InstaIcon.png";
import FacebookIcon from "../..//Svg/WebsiteIcons/FacebookIcon.png";
import LinkIcon from "../..//Svg/WebsiteIcons/LinkIcon.png";
import TikTokIcon from "../..//Svg/WebsiteIcons/TikTokIcon.png";
import Arrow from "../..//Svg/WebsiteIcons/LinkArrow.png";

export default function Footer() {

  const emailRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    
    setError("")
    setLoading(true)
    
    addDoc(collection(getFirestore(), "updateSubs"), {
        emailRef: emailRef.current.value ,

    });

    setLoading(false)
  }

  return (
    <div className="FooterBackgroundBox">
      <div className="LeftInfo">
        <p className="LeftTitle">SOUNDBAR</p>
        <p className="Address">1440 W Taylor St Suite 1948</p>
        <p className="Address">Chicago, IL 60607</p>
        <div className="EmailLayout">
          <p className="EmailPartOne">Email: </p>
          <p className="EmailPartTwo">team@soundbar.co</p>
        </div>
        <div className="EmailLayoutBottom">
          <p className="EmailPartOne">Phone: </p>
          <p className="EmailPartTwo">(773)-272-6162</p>
        </div>
      </div>
      <div className="MiddleInfo">
        <p className="MiddleTitle">LINKS</p>
        <div className="LinksPages">
          <div className="LinkGroupOne">
            <div className="LinkLayout">
              <img className="LinkArrow" src={Arrow}></img>
              <Link to={"/"} className="Link" style={{ textDecoration: 'none' }}>Home</Link>
            </div>
            <div className="LinkLayout">
              <img className="LinkArrow" src={Arrow}></img>
              <Link to={"/affiliate"} className="Link" style={{ textDecoration: 'none' }}>Affiliates</Link>
            </div>
            <div className="LinkLayout">
              <img className="LinkArrow" src={Arrow}></img>
              <Link to={"/explore"} className="Link" style={{ textDecoration: 'none' }}>Explore</Link>
            </div>
          </div>
          <div className="LinkGroupTwo">
            <div className="LinkLayout">
              <img className="LinkArrow" src={Arrow}></img>
              <Link to={"/account"} className="Link" style={{ textDecoration: 'none' }}>Account</Link>
            </div>
            <div className="LinkLayout">
              <img className="LinkArrow" src={Arrow}></img>
              <Link to={"/support"} className="Link" style={{ textDecoration: 'none' }}>Help</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="MiddleInfo">
        <p className="MiddleTitle">INFO</p>
        <div className="LinksPages">
          <div className="LinkGroupOne">
            <div className="LinkLayout">
              <p className="InfoLink">Privacy Policy</p>
            </div>
            <div className="LinkLayout">
              <p className="InfoLink">Terms and Service</p>
            </div>
            <div className="LinkLayout">
              <p className="InfoLink">EULA</p>
            </div>
            <div className="LinkLayout">
              <p className="InfoLink">Contact Us</p>
            </div>
          </div>
        </div>
      </div>
      <div className="RightInfo">
        <p className="RightTitle">SOUNDBAR UPDATES</p>
        <p className="RightSubtitle">
          New features, merchandise drops, and industry events!
        </p>
        <Form className="EmailUpdatesLayout">
          <div className="EmailBox">
            <Form.Control className="EmailText" placeholder="Email"></Form.Control>
          </div>
          <div className="EmailSubmitBox">
            <Button style={{border: 'none'}} type= "submit" className="EmailSubmitText">Submit</Button>
          </div>
        </Form>
        <div className="EmailUnderline"></div>
        <div className="SocialIconLayout">
          <img className="SocialIcon" src={InstaIcon}></img>
          <img className="SocialIcon" src={FacebookIcon}></img>
          <img className="SocialIcon" src={LinkIcon}></img>
          <img className="SocialIcon" src={TikTokIcon}></img>
        </div>
      </div>
    </div>
  );
}
