import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  where,
  orderBy,
  getFirestore,
  limit,
  query,
} from "@firebase/firestore";
import { date } from "joi";
import axios from "axios";

import { getFunctions, httpsCallable } from "@firebase/functions";

import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { arrayUnion } from "@firebase/firestore";


const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [followers, setFollowers] = useState("");
  const [following, setFollowing] = useState("");
  const [listens, setListens] = useState("");
  const [bio, setBio] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const [personalAffiliateReferralCode, setPersonalAffiliateReferralCode] =
    useState("");

  const [notifications, setNotifications] = useState([
    {
      songID: "",
      commentID: "",
      replyID: "",
      profileImages: "",
      songImage: 0,
      username: "",
      userID: "",
      message: "",
      type: 0,
      timeStamp: new Date(),
    },
  ]);

  const [userTracks, setUserTracks] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [userReposts, setUserReposts] = useState([
    {
      artist: "",
      artist_id: "",
      caption: "",
      clip_start_time: "",
      comments: 0,
      full_song_link: "",
      hastags: [""],
      id: "",
      image_link: "",
      likes: 0,
      listens: 0,
      shares: 0,
      song_name: "",
      timeStamp: Date.now,
    },
  ]);

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (user != null) {
        const userSnap = getDoc(doc(getFirestore(), "users", user.uid));

        const notificationsSnap = query(
          collection(getFirestore(), "users", user.uid, "notifications"),
          limit(10),
          orderBy("timestamp", "desc")
        );

        getDocs(notificationsSnap).then((response) => {
          setNotifications(
            response.docs.map((doc) => ({
              songID: doc.get("songID"),
              profile_link: doc.get("profile_link"),
              commentID: doc.get("commentID"),
              replyID: doc.get("replyID"),
              profileImages: doc.get("profileImages"),
              songImage: doc.get("songImage"),
              username: doc.get("username"),
              userID: doc.get("userID"),
              message: doc.get("message"),
              type: doc.get("type"),
              timeStamp: doc.get("timeStamp"),
            }))
          );
        });

        const userTracksSnap = query(
          collection(getFirestore(), "users", user.uid, "tracks")
        );

        getDocs(userTracksSnap).then((response) => {
          const songIds = response.docs.map((doc) => doc.id);
          console.log("songIds:", songIds);

          const songsRef = collection(getFirestore(), "songs");
          const matchingSongsPromises = [];

          songIds.forEach((songId) => {
            console.log("songId:", songId);
            const queryRef = query(songsRef, where("id", "==", songId));
            const matchingSongPromise = getDocs(queryRef)
              .then((songSnap) => {
                const songData = songSnap.docs.map((doc) => ({
                  artist: doc.get("artist"),
                  artist_id: doc.get("artist_id"),
                  caption: doc.get("caption"),
                  clip_start_time: doc.get("clip_start_time"),
                  comments: doc.get("comments"),
                  full_song_link: doc.get("full_song_link"),
                  hashtags: doc.get("hashtags"),
                  id: doc.get("id"),
                  image_link: doc.get("image_link"),
                  likes: doc.get("likes"),
                  listens: doc.get("listens"),
                  shares: doc.get("shares"),
                  song_name: doc.get("song_name"),
                  timestamp: doc.get("timestamp"),
                }));
                console.log("songData:", songData);
                return songData;
              })
              .catch((error) => {
                console.error("Error fetching matching song:", error);
                return [];
              });
            matchingSongsPromises.push(matchingSongPromise);
          });

          Promise.all(matchingSongsPromises)
            .then((matchingSongs) => {
              const flatMatchingSongs = matchingSongs.flat();
              console.log("flatMatchingSongs:", flatMatchingSongs);
              setUserTracks(flatMatchingSongs);
            })
            .catch((error) => {
              console.error("Error fetching matching songs:", error);
            });
        });

        const userRepostsSnap = query(
          collection(getFirestore(), "users", user.uid, "reposts")
        );

        getDocs(userRepostsSnap).then((response) => {
          const songIds = response.docs.map((doc) => doc.id);
          console.log("songIds:", songIds);

          const songsRef = collection(getFirestore(), "songs");
          const matchingSongsPromises = [];

          songIds.forEach((songId) => {
            console.log("songId:", songId);
            const queryRef = query(songsRef, where("id", "==", songId));
            const matchingSongPromise = getDocs(queryRef)
              .then((songSnap) => {
                const songData = songSnap.docs.map((doc) => ({
                  artist: doc.get("artist"),
                  artist_id: doc.get("artist_id"),
                  caption: doc.get("caption"),
                  clip_start_time: doc.get("clip_start_time"),
                  comments: doc.get("comments"),
                  full_song_link: doc.get("full_song_link"),
                  hashtags: doc.get("hashtags"),
                  id: doc.get("id"),
                  image_link: doc.get("image_link"),
                  likes: doc.get("likes"),
                  listens: doc.get("listens"),
                  shares: doc.get("shares"),
                  song_name: doc.get("song_name"),
                  timestamp: doc.get("timestamp"),
                }));
                console.log("songData:", songData);
                return songData;
              })
              .catch((error) => {
                console.error("Error fetching matching song:", error);
                return [];
              });
            matchingSongsPromises.push(matchingSongPromise);
          });

          Promise.all(matchingSongsPromises)
            .then((matchingSongs) => {
              const flatMatchingSongs = matchingSongs.flat();
              console.log("flatMatchingSongs:", flatMatchingSongs);
              setUserReposts(flatMatchingSongs);
            })
            .catch((error) => {
              console.error("Error fetching matching songs:", error);
            });
        });

        // console.log("Email: ", user.email)
        userSnap.then((value) => {
          setUsername(value.get("username"));
          setProfileImage(value.get("profile_link"));
          setFullName(value.get("full_name"));
          setFollowers(value.get("followers_count"));
          setFollowing(value.get("following_count"));
          setListens(value.get("listens"));
          setBio(value.get("bio"));

          console.log("profile_link: ", value.get("profile_link"));
          console.log("username: ", value.get("username"));
          console.log("user: ", value);
        });
      }

      setLoading(false);
    });
    return unsubscribe;
  }, []);

  function checkIsFollowing(artistID) {
    return new Promise((resolve, reject) => {
      const userSnap = getDoc(
        doc(getFirestore(), "users", artistID, "following", artistID)
      );

      userSnap
        .then((value) => {
          if (value.exists) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        })
        .catch((err) => {
          return reject(false);
        });
    });
  }

  function changeEmail(newEmail) {
    const user = currentUser;
    return new Promise((resolve, reject) => {
      user
        .updateEmail(newEmail)
        .then(() => {
          return resolve(true);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  function followUser(artistID) {
    const followingFollowers = doc(
      getFirestore(),
      "users",
      artistID,
      "followers",
      currentUser.uid
    );
    const followingRef = doc(getFirestore(), "users", artistID);

    const followerFollowersRef = doc(
      getFirestore(),
      "users",
      currentUser.uid,
      "followers",
      artistID
    );
    const followerFollowingRef = doc(
      getFirestore(),
      "users",
      currentUser.uid,
      "following",
      artistID
    );

    const followerRef = doc(getFirestore(), "users", currentUser.uid);

    followerFollowersRef
      .then((snap) => {
        if (snap.exists) {
          updateDoc(followingFollowers, {
            id: currentUser.uid,
            timeStamp: new Date(),
            followedBack: true,
          })
            .then(() => {
              updateDoc(followerFollowingRef, {
                id: artistID,
                timeStamp: new Date(),
              }).then(() => {
                updateDoc(followingRef, {
                  followers: getFirestore().increment(1),
                }).then({});

                updateDoc(followerRef, {
                  followers: getFirestore().increment(1),
                });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          updateDoc(followingFollowers, {
            id: currentUser.uid,
            timeStamp: new Date(),
            followedBack: false,
          }).then(() => {
            updateDoc(followerFollowingRef, {
              id: artistID,
              timeStamp: new Date(),
            }).then(() => {
              updateDoc(followingRef, {
                followers: getFirestore().increment(1),
              }).then({});

              updateDoc(followerRef, {
                followers: getFirestore().increment(1),
              });
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function unfollowUser(artistID) {
    const followingFollowers = doc(
      getFirestore(),
      "users",
      artistID,
      "followers",
      currentUser.uid
    );
    const followingRef = doc(getFirestore(), "users", artistID);

    const followerFollowingRef = doc(
      getFirestore(),
      "users",
      currentUser.uid,
      "following",
      artistID
    );
    const followerRef = doc(getFirestore(), "users", currentUser.uid);

    followingFollowers.deleteDoc().then(() => {
      followerFollowingRef.deleteDoc().then(() => {
        followingRef
          .update({
            followers: getFirestore().increment(-1),
          })
          .then({});

        followerRef.update({
          followers: getFirestore().increment(-1),
        });
      });
    });
  }

  function updateProfileInfo(info) {
    const userProfileRef = doc(getFirestore(), "users", currentUser.uid);
    console.log("update profile: " + currentUser.uid);
    updateDoc(userProfileRef, {
      full_name: info.full_name,
      username: info.username,
      bio: info.bio,
    });
  }

  function checkUsername(newUsername) {
    return new Promise((resolve, reject) => {
      const usersCollection = collection(
        getFirestore(),
        "users",
        where("username", "==", { newUsername })
      );

      getDocs(usersCollection).then((newUsers) => {
        if (newUsers.empty) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  //CHECKS IF USERNAME EXISTS
  async function usernameExists(username) {
    const lowercaseUsername = username.toLowerCase();
    const db = getFirestore();
    const usersRef = collection(db, "users");

    const q = query(usersRef, where("username", "==", lowercaseUsername));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  //GETS ID FROM USERNAME
  async function getUidByUsername(username) {
    const db = getFirestore();
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    } else {
      return null;
    }
  }

  function createReferralCode(uid) {
    const referralCode = uid.slice(0, 7).toUpperCase();
    setPersonalAffiliateReferralCode(referralCode);
    return referralCode;
  }

  //MN9EOK7
  async function incrementAffiliatesReferred(referralCode, referredUid) {
    const referralCodeUppercase = referralCode.toUpperCase();
    console.log(referralCodeUppercase);
    console.log(referredUid);
    const db = getFirestore();
    const affiliatesRef = collection(db, "affiliates");
    const q = query(
      affiliatesRef,
      where("referralCodePersonal", "==", referralCodeUppercase)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const affiliateDoc = querySnapshot.docs[0];
      const currentCount = affiliateDoc.data().affiliatesReferred || 0;
      const updatedCount = currentCount + 1;

      // Update the affiliatesReferred count and add the referredUid to the referredAffiliates array
      await setDoc(
        doc(db, "affiliates", affiliateDoc.id),
        {
          affiliatesReferred: updatedCount,
          referredAffiliates: arrayUnion(referredUid),
        },
        { merge: true }
      );
    } else {
      console.error("Affiliate with referral code not found");
    }
  }

  async function saveAffiliateData(data) {
    const db = getFirestore();
    const username = data.username;

    try {
      const uid = await getUidByUsername(username);
      if (uid) {
        const referralCode = createReferralCode(uid);
        const affiliateData = {
          ...data,
          referralCodePersonal: referralCode,
        };
        const affiliateDoc = doc(db, "affiliates", uid);
        await setDoc(affiliateDoc, affiliateData, { merge: true });
        console.log("Document written with ID: ", uid);

        // Check if there's a referralCodeUsed in the survey data and increment affiliatesReferred
        if (data.referralCodeUsed) {
          if (data.referralCodeUsed !== referralCode) {
            // Pass referredUid to incrementAffiliatesReferred
            await incrementAffiliatesReferred(data.referralCodeUsed, uid);
          }
        }
      } else {
        console.error("User not found");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  function getArtist(artistID) {
    return new Promise((resolve, reject) => {
      const userSnap = getDoc(doc(getFirestore(), "users", artistID));

      userSnap
        .then((value) => {
          const profileImage = value.get("profile_link");
          const username = value.get("username");
          const fullName = value.get("full_name");
          const followersCount = value.get("followers_count");
          const followingCount = value.get("following_count");
          const bio = value.get("bio");

          const newProfile = {
            artist_Id: artistID,
            profileImage: profileImage,
            username: username,
            fullName: fullName,
            followersCount: followersCount,
            followingCount: followingCount,
            bio: bio,
          };

          console.log(newProfile);
          return resolve(newProfile);
        })
        .catch((err) => {
          return reject(new Error(err));
        });
    });
  }

  function likeSong(songID) {}

  function uploadProfileImage(image) {
    const storageRef = ref(
      getStorage(),
      `users/${currentUser.email}/userImages/profileImage/`
    );
    const uploadTaskImage = uploadBytesResumable(storageRef, image);

    uploadTaskImage.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("Upload failed");
      },
      () => {
        getDownloadURL(uploadTaskImage.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);

          const userProfileRef = doc(getFirestore(), "users", currentUser.uid);
          console.log("update profile: " + currentUser.uid);
          updateDoc(userProfileRef, {
            profile_link: downloadURL,
          });
        });

        // get image URL and store in database
      }
    );
  }

  const [loading, setLoading] = useState(true);

  async function sendEmail(to, subject, templateId, dynamicTemplateData) {
    const sendEmailFunction = httpsCallable(getFunctions(), "sendEmail");
    
    try {
      const response = await sendEmailFunction({
        to: to,
        subject: subject,
        templateId: templateId,
        dynamicTemplateData: dynamicTemplateData,
      });
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    console.error("Login info: " + email + ": " + password);

    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function getUserTracks() {}

  const value = {
    currentUser,
    username,
    fullName,
    profileImage,
    followers,
    following,
    listens,
    bio,
    getUserTracks,

    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    getArtist,
    updatePassword,
    notifications,
    checkIsFollowing,
    followUser,
    unfollowUser,

    // affiliateAccounts,
    updateProfileInfo,
    changeEmail,
    uploadProfileImage,
    checkUsername,
    userTracks,
    userReposts,
    usernameExists,
    saveAffiliateData,
    personalAffiliateReferralCode,
    sendEmail
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
