import "../../Css/AffiliateApplicationPage.css";
import SoundbarLogo from "../../Svg/SoundbarLogoCircle.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import { useLocation } from "react-router-dom";

export default function AffiliateApplicationCompletePage() {
  const { personalAffiliateReferralCode } = useAuth();
  const location = useLocation();
  const myData = location.state;
  return (
    <>
      <BrowserView>
        <div className="AffiliateApplicationCompletePage">
          <div className="AffiliateApplicationSuccessBox">
            <img src={SoundbarLogo} className="soundbarLogoCircle"></img>
            <p className="AffiliateCongrats">
              Congratulations on your application!
            </p>
            <p className="AffiliateDetailsEmailed">
              Details have been emailed to {myData.email}
            </p>
            <p className="referralCodeIs">
              Your referral code is {personalAffiliateReferralCode}
            </p>
            <div className="homeButton">
              <p className="homeButtonText">HOME</p>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="AffiliateApplicationCompletePageMobile">
          <p>CONGRATS YOU DID IT</p>
        </div>
      </MobileView>
    </>
  );
}
