import React, { useEffect, useState } from "react";
import SoundbarLogo from "../../Svg/SoundbarLogo.svg";
import "../../Css/HomePage.css";

import ProfileImage from "../../Svg/TestProfileImage.png";
import ContentImage from "../../Svg/TestContentImage.PNG";
import { AuthProvider, useAuth } from "../Context/AuthContext";

export default function Notifications() {

  const { notifications } = useAuth();
  const { username } = useAuth();
  const { checkIsFollowing } = useAuth();
  const { followUser } = useAuth();
  const { unFollowUser } = useAuth();
  const { following } = useAuth();


  useEffect(() => {

    console.log("Notifications: " + notifications)

    notifications.map((item) => {
    
      if (item.type === "Follow") {

        checkIsFollowing(item.userID).then((result) => {
          setNotificationStatus(prevState => ({
            notifications: {
              ...notifications,
              [item.userID]: result
            }
          }))
  

        }).catch((error) => {
          console.log(error)
          // handle the error
        });

      }
    })
  }, [notifications, following]);

  const [notificationStatus, setNotificationStatus] = useState({
    id: "",
    isFollowing: false
  })

  function followToggle(status) {
    if (status) {
      unFollowUser()
    }else{
      followUser()
    }

  }

  const notificationsMap = notifications.map((item) => {
    let message = "";

    if (item.type === "Welcome") {
      message = ("Welcome to Soundbar, " + username);
    } else if (item.type === "Following") {
      message = "@" + item.username + " started following you";
    } else if (item.type === "LikedSong") {
      message = "@" + item.username + " liked your track";
    } else if (item.type === "Comment") {
      message = "@" + item.username + " commented on your track";
    } else if (item.type === "LikedComment") {
      message = "@" + item.username + " liked your comment";
    } else if (item.type === "RepliedComment") {
      message = "@" + item.username + " replied to your comment";
    } else if (item.type === "RepostedSong") {
      message = "@" + item.username + " reposted your track";
    } else if (item.type === "Released") {
      message = "@" + item.username + " released a new track";
    }

    

    return (
      <div className="NotificationCell">
        {(() => {
          if (item.type === "Welcome") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={SoundbarLogo}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else if (item.type === "Following") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <div onClick={() => followToggle(notificationStatus[item.userID])} className="followButton">
                    {(notificationStatus[item.userID] != null && notificationStatus[item.userID] != true) ? 
                    <p className="followText">UnFollow</p> :
                    <p className="followText">Follow</p>}
                  </div>
                </div>
              </div>
            );
          } else if (item.type === "LikedSong") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          } else if (item.type === "Comment") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifMessageContent">
                        "Test comment test comment"
                      </p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          } else if (item.type === "LikedComment") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifMessageContent">
                      "Test comment test comment"
                      </p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          } else if (item.type === "RepliedComment") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifMessageContent">
                      "Test comment test comment"
                      </p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          } else if (item.type === "RepostedSong") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          } else if (item.type === "Released") {
            return (
              <div className="NotificationCell">
                <div className="NotificationContent">
                  <div className="NotificationContentLeft">
                    <img className="NotifProfileImage" src={item.profileImages}></img>
                    <div className="NotificationText">
                      <p className="notifMessage">{message}</p>
                      <p className="notifTimeStamp">{item.time}</p>
                    </div>
                  </div>
                  <img className="NotifContentImage" src={item.songImage}></img>
                </div>
              </div>
            );
          }
        })()}

        <div className="NotificationSpacer"></div>
      </div>
    );
  });

  return <div className="NotificationsSidebar">{notificationsMap}</div>;
}
